<template>
   <input  :value="getvalue(value)"  v-on:input="$emit('input', $event.target.value)"  name="date" class="form-control" type="text"  :id="id" readonly="readonly">                 
</template>

<script>
import $ from 'jquery';
import 'jquery-ui';
import datepickerFactory from 'jquery-datepicker';
import moment from "moment"
datepickerFactory($);
export default {
    name: 'Mydatepicker',
     props: {
        id:{
           type: String,
            required: true, 
        },
    value: {
      required: true,
    }
},
  data () {
    return {
        Date: '',
    }
  },
  watch:{
     value :function(val) { 
        this.Date = val
     }
  },
  methods: {
    getvalue(d){
        if (d==""){
            return ""
        } else if (d === undefined) {
            return "";
        } else if (typeof d == 'object'){
            return moment(d).format('DD-MM-YYYY');
        } else {
            return d
        }
    },
     updateValue: function (value) {
      this.$emit('input', value);
    },
     onChange(event) { // Supports .lazy
      // Can add validation here
      console.log("date===>",event)
      this.$emit('input', event.target.value);
    },
  },
  mounted () {
    // render the recaptcha widget when the component is mounted
      var vm = this

       $('#'+this.id).datepicker({
       changeMonth: true,
    changeYear: true,
    yearRange: "-100:+0", 
     language: "en",
      dateFormat: 'dd-mm-yy',
      maxDate: new Date(),
      onSelect: function(dateText) { 
        vm.date = dateText
        vm.$emit('myfunction', dateText);
      }
   })

  }
}
</script>