<template>
   <div class="announcement-page">
    <h1>Announcements</h1>
    <div v-if="loading" class="loading">Loading announcements...</div>
    <div v-else>
      <div v-if="announcements.length > 0" class="announcement-list">
        <div v-for="announcement in announcements" :key="announcement.id" class="announcement-item">
          <h3>{{ announcement.title }}</h3>
          <p>{{ announcement.content }}</p>
          <small>{{ formatDate(announcement.date) }}</small>
        </div>
      </div>
      <div v-else class="no-announcements">No announcements available.</div>
    </div>
  </div>
</template>

<script>
import helper from "../helper.js"
//import swal from 'sweetalert';


export default {
  data() {
    return {
      announcements: [],
      loading: true,
    };
  },
  created() {
    this.fetchAnnouncements();
  },
  methods: {
    // Fetch announcements from API
     fetchAnnouncements() {
      try {
        let self = this
        let article = {}
        this.axios.post(helper.SERVICEURL+"announcements/get", article) .then(response => {
                if(response.data){
                    self.announcements = response.data;
                }
            });
        
      } catch (error) {
        console.error('Error fetching announcements:', error);
      } finally {
        this.loading = false;
      }
    },
    // Format the announcement date
    formatDate(dateStr) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateStr).toLocaleDateString(undefined, options);
    }
  }
};
</script>

<style scoped>
.announcement-page {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.loading {
  text-align: center;
  font-size: 18px;
}

.announcement-list {
  margin-top: 20px;
}

.announcement-item {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
}

.announcement-item h3 {
  margin: 0 0 10px;
}

.announcement-item p {
  margin: 0 0 10px;
}

.announcement-item small {
  color: #888;
}

.no-announcements {
  text-align: center;
  font-size: 16px;
  color: #888;
}
</style>

<style>
label.mod-pass-hide{
  position: absolute;
}
</style>
