<template>
  <div>
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">Site settings</a></h1>
      <form class="form-inline">
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="!isadd" @click="isadd=true" >ADD Gender</button>
        <button type="button" class="btn btn-danger" v-if="isadd" @click="isadd=false" ><i class="far fa-times-circle"></i></button>
      </form>
    </nav>
  </div>


  <div class="card shadow" v-if="isadd">
    <div class="card-body">
      <div>
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Is Mandatory Fields Need ?</label>
                <div >
                <label class="switch " style="float: left;">
                <input type="checkbox" v-model="sitesettings.IsMandatory"  class="success">
                <span class="slider"></span>
                </label>
            </div>  
            </div>
          </div>
      

            <button type="button" class="btn btn-default" style="float: right;" @click="save(false)" >Save</button>
        </form>
      </div>
    </div>
      
  </div>    
    
    <transition name="bounce">
      <div id="maxalert" v-if="maxalert"> {{max}} <i class="far fa-times-circle" @click="maxalert=false"></i></div>
    </transition>
  </div>
</template>

<script>
import helper from "../helper.js"
import swal from 'sweetalert';
import { process } from '@progress/kendo-data-query';


export default {
  components: {

  },
  data() {
    return {
      sitesettings:{
        "UID":"sitesettings",
        "IsMandatory":false
        },
      gridData: [],
      sort: null,
      skip: 0,
      take: 10,
      total: 0,
      selectedField: 'selected',
      Rawtemplate: "<a class='k-button' href='/Home/Index'>Redirect</a>",   
      Gender : "",
      IsActive: true,
      maxalert:false,
      max:0,
      Mode:"NEW",
      list:[],
      isadd:true,
      dataState: {
        take: 0,
        skip: 0,
      },
      columns: [
        // { field: 'selected', width: '50px', headerSelectionValue: this.areAllSelected },
        {
          title: 'Gender',
          field: 'gender',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Is Active',
          field: 'IsActive',
          filter: 'boolean',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        { cell: 'myTemplate1', width: '100px' }
        
      ],
      
    };
  },
   created: function () {
     this.loadusers();
     this.loadsitesettings();
     this.createDataState({
      take: 10,
      skip: 0,
    });
  },
  
  methods: {
    
    validate(){
      //eslint-disable-next-line
      const regexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (this.Gender==""){
          return this.error("Gender Name is required!")
        }
        
       return true
    },
    error(text){
      var _this = this
      if (this.maxalert){
         this.maxalert = false
      }
       this.max = text
         this.maxalert = true
         setTimeout(function(){
                    _this.maxalert = false;
                }, 3000);
                return false;
    },
      isNumber (evt){
    const keysAllowed= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed = evt.key;
    
    if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault()
    }
    },
    edit(n){
      this.Mode="UPDATE"
      this.IsActiveOld = n.IsActive
      this.GenderOld = n.gender
      this.IsActive = n.IsActive
      this.Gender = n.gender
      this.isadd=true
    },
    del(ID){
      const article = {"gender": ID.gender};       
      this.axios.post(helper.SERVICEURL+"gender/delete", article)
          .then(response => {
            if(response.data.status){
                swal({  
                    text: response.data.Msg, 
                    timer:2000,
                    buttons: false,
                  }); 
                   this.loadusers()
                  
            }  else {
                swal({  
                    text: response.data.Msg,
                    timer:2000, 
                    buttons: false,
                  }); 
            }     
          });
    },
    loadusers(e){
      var self = this
      this.Mode="NEW"
      var article = {};
      if(e != undefined){
          article = e
      }  
        this.axios.post(helper.SERVICEURL+"gender/get", article)
          .then(response => {
            if(response.data){
              self.list= response.data
              self.gridData = self.list.slice(0, 10) 
              self.total = self.gridData.length        
            }      
          });

    },
    save(){
    
       var self = this
       var Actionurl= "user/savesitesettings"
        this.axios.post(helper.SERVICEURL+Actionurl, this.sitesettings)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,  
                }); 
                self.loadsitesettings()
            }  else {
              swal({  
                text: response.data.Msg,
                timer:2000, 
                buttons: false,
              });
            }     
          });

    },
    createDataState(dataState) {
      this.gridData = process(this.list.slice(0), dataState);
      this.total = this.gridData.length
      this.dataState = dataState;
    },
     loadsitesettings() {
        var Actionurl = "user/getsitesettings"
        const article = {};
        var self = this
        this.axios.post(helper.SERVICEURL+Actionurl, article)
        .then(response => {
          if(response.data) {            
            if(response.data){
            self.sitesettings=  response.data[0]
              localStorage.setItem("sitesettings",JSON.stringify(response.data))
            }          
          }    
      });      
    },
    dataStateChange(e) {
      if (e.event) {
        let isColumnActive =false

        // @ts-ignore
        let changedColumn = this.columns.find(
          (column) => column.field === e.event.field
        );

        if (changedColumn) {
          changedColumn.headerClassName = isColumnActive
            ? 'customMenu active'
            : '';
        }
      }
      this.createDataState(e.data);
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
  },
};
</script>

<style>
  
</style>
