<template>
  <div>
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">Application Import</a></h1> 
      <form class="form-inline">        
        <button type="button" class="btn" style="background-color:#3297fa"><a style=" color:white" href="img/brand/Book1.csv" download>Download Sample File</a></button>
        
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" @click="ShowTable=true" v-if="HavingError" >Import Log</button>
      </form>     
    </nav>
  </div>
  </div>
  <div class="card shadow" style="margin-top:60px" v-if="!ShowTable">
    <div class="card-body">
      <div class="grid">
        <div class="form-group col-md-4">              
          <label for="inputEmail4">Upload your file here</label>
          <div style="padding:5px;">
            <input type="file" id="csvFile" accept=".csv" />
          </div>              
        </div>
        <div class="form-group col-md-4" style="margin-left:5px" v-if="!PageLoading">              
          <button type="button" class="btn btn-default" style="float: left;" @click="submit" >Submit</button> <br>
        </div>
      </div>
      <div v-if="PageLoading">
        <div class="col-12" style="text-align: left; margin-left:50px">      
          <div class="loader--ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>      
      <transition name="modal" v-if="ShowTable">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div>
                <slot name="header">
                  <div class="top-navbar">
                    <nav class="navbar navbar-light justify-content-between">
                      <h1><a class="navbar-brand">Import Log</a></h1>
                      <form class="form-inline">
                        <button type="button" class="btn btn-danger" @click="ShowTable=false" ><i class="far fa-times-circle"></i></button>                        
                      </form>
                    </nav>
                  </div>
                </slot>
              </div>

              <div class="modal-body">
                <slot name="body">
                  <div class="form-group col-md-12">
                    <table class="table table-striped table-sm table-bordered">
                      <tr>
                        <th class="form-control-sm">Row</th>
                        <th class="form-control-sm">Status</th>
                        <th class="form-control-sm">Reason</th>
                      </tr>
                      <tr v-for="n in ImportLog" :key="n.Row" style="color:black">
                        <td>{{n.Row}}</td>
                        <td>{{n.status}}</td>
                        <td>{{n.Reason}}</td>
                      </tr> 
                    </table>
                  </div> 
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition> 
      
</template>

<script>
import helper from "../helper.js"
import swal from 'sweetalert';
export default {
  components: {
  },
  data() {
    return {
      PageLoading : false,
      ShowTable : false,
      data : [],
      HavingError : false,
      ImportLog: []
    };
  },
   created: function () {
  },
  
  methods:{
    submit(e) {
    var that = this
    const csvFile = document.getElementById("csvFile");
    e.preventDefault();
      const input = csvFile.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
      const text = e.target.result;
      this.data = helper.csvToArray(text)
      that.PageLoading = true
      that.axios.post(helper.SERVICEURL+"applications/bulksave", this.data)
      .then(response => {
        that.PageLoading = false
            if(response.data){
              if(response.data.Data.length > 0) {
                that.HavingError = true
                that.ImportLog= response.data.Data
                that.ShowTable = true 
              }else{
                that.ImportLog = []
                swal({  
                  text: "Save success",
                  timer:2000, 
                  buttons: false,
                }); 
              }                                 
            }      
          });
    };
      reader.readAsText(input);      
  },
  

  }  
};
</script>

<style></style>