/*!

=========================================================
* Vue Argon Dashboard - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
//import { VueReCaptcha } from 'vue-recaptcha-v3';


// import swal from 'sweetalert';

// import '@progress/kendo-ui' 
// import '@progress/kendo-theme-default/dist/all.css'
// import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'


// import kendo from '@progress/kendo-ui'
// import '@progress/kendo-theme-default/dist/all.css'
// import { Grid } from '@progress/kendo-vue-grid'
// import {
//     DropdownsInstaller,
//     GridInstaller, 
//     DataSourceInstaller,
//     DateinputsInstaller,
// } from '@progress/kendo-all-vue-wrapper/dist/cdn/kendo-all-vue-wrapper.min.js'



const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
appInstance.use(VueAxios, axios)
appInstance.component('Datepicker', VueDatepickerUi)
appInstance.use(VueToast);
appInstance.use(VueSidebarMenu)
// appInstance.use(VueReCaptcha, {
//     siteKey: '6Lcn7D8qAAAAAB8DkJa08NS8Ys4xbhQov1_vTBKw',  // Replace with your actual reCAPTCHA site key
//     loaderOptions: {
//       autoHideBadge: false,  // Optionally hide the reCAPTCHA badge
//     }
//   });


// const Swal = require('sweetalert2')

// appInstance.use(GridInstaller)
// appInstance.use(Grid)

// appInstance.use(kendo);

// appInstance.use(Grid);
// appInstance.use(DropdownsInstaller);
// appInstance.use(GridInstaller);
// appInstance.use(DataSourceInstaller);
// appInstance.use(DateinputsInstaller);





