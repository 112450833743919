<template>
  <div>
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">District Master</a></h1>
      <form class="form-inline">
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="!isadd" @click="isadd=true" >ADD District</button>
        <button type="button" class="btn btn-danger" v-if="isadd" @click="isadd=false" ><i class="far fa-times-circle"></i></button>
      </form>
    </nav>
  </div>
    <div class="grid"  style="margin-top:60px;" v-if="!isadd">
      <!-- <div class="card shadow">           
            <div class="card-body">
              <table class="table table"  v-if="!isadd" >
                <thead class="thead-dark">
                <tr style="background-color:#3297fa">
                  <th style="background-color:#3297fa; color:white" >State ID</th>
                  <th style="background-color:#3297fa; color:white" >District ID</th>
                  <th style="background-color:#3297fa; color:white" >District</th>
                  <th style="background-color:#3297fa; color:white">Status</th>
                </tr>
                </thead>
                <tr v-for="n in list" :key="n.ID" style="color:black">
                  <td>{{n.stateID}}</td>
                  <td>{{n.districtID}}</td>
                  <td>{{n.district}}</td>
                  <td>{{n.IsActive}}</td>                  
                  <td style="text-align: center;width:20px"><button class="btn btn-sm btn-info" type="button" @click="edit(n)"><i class="fas fa-edit"></i></button> <button class="btn btn-sm btn-danger" type="button" @click="del(n.districtID)"><i class="fas fa-trash"></i></button></td>
                </tr>              
              </table>              
            </div>
    </div> -->
     <Grid
    ref="grid"    
    :data-items="gridData"
    scrollable: true
    :pageable = "true"
    :take="dataState.take"
    :skip="dataState.skip"
    :sort="dataState.sort"
    :total="total"
    :loader="loader"
    :selected-field="selectedField"
    @datastatechange="dataStateChange"
    @filterchange="filterChange"
    @selectionchange="onSelectionChange"
    :columns="columns"
    :filter="dataState.filter"
    :sortable="true"
  >
  <!-- @rowclick="onRowClick" 
  @headerselectionchange="onHeaderSelectionChange"-->
   <template v-slot:myTemplate="{ props }">
      <custom
        :column="props.column"
        :filterable="props.filterable"
        :filter="props.filter"
        :sortable="props.sortable"
        :sort="props.sort"
        :columns="columns"
        @sortchange="(e) => props.onSortchange(e)"
        @filterchange="(e) => props.onFilterchange(e)"
        @closemenu="(e) => props.onClosemenu(e)"
        @contentfocus="(e) => props.onContentfocus(e)"
        @columnssubmit="onColumnsSubmit"
        
      />
  </template>
  <template v-slot:myTemplate1="{props}">
    <actionbtn :data-item="props.dataItem" 
          @edit="edit"
          @remove="del"
          @download="download"/>
  </template>
  </Grid>
   

    </div>

  <div class="card shadow" v-if="isadd">
    <div class="card-body">
      <div>
        <form>
            <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">State ID</label>
              <input type="text" v-model="StateID" class="form-control" name="StateID" placeholder="Enter State ID">      
            </div>
          </div>
            <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">District ID</label>
              <input type="text" v-model="DistrictID" class="form-control" name="DistrictID" placeholder="Enter District ID">      
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">District</label>
              <input type="text" v-model="District" class="form-control" name="District" placeholder="Enter District">      
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputPassword4">Status</label>
              <div >
                <label class="switch " style="float: left;">
                <input type="checkbox" v-model="IsActive"  class="success">
                <span class="slider"></span>
                </label>
            </div>
            </div> 
          </div> 

            <button type="button" class="btn btn-default" style="float: left;" v-if="isadd && Mode=='NEW'" @click="save(true)" >Save & add</button> 
            <button type="button" class="btn btn-success" style="float: right;" v-if="isadd && Mode=='NEW'" @click="save(false)" >Save & close</button>
            <button type="button" class="btn btn-default" style="float: right;" v-if="isadd && Mode=='UPDATE'" @click="save(false)" >Save & close</button>
        </form>
      </div>
    </div>
      
  </div> 
    
    <transition name="bounce">
      <div id="maxalert" v-if="maxalert"> {{max}} <i class="far fa-times-circle" @click="maxalert=false"></i></div>
    </transition>
  </div>
</template>

<script>
import helper from "../helper.js"
import { Grid, filterGroupByField } from '@progress/kendo-vue-grid';
import ColumnMenu from './ColumnMenu';
import CommandCell from './CommandCell';
import swal from 'sweetalert';
import { process } from '@progress/kendo-data-query';


export default {
  components: {
    'Grid': Grid,
    custom: ColumnMenu,
    'actionbtn': CommandCell, 
  },
  data() {
    return {
      gridData: [],
      StateID:"",
      DistrictID : "",
      District : "",
      IsActive: true,
      maxalert:false,
      max:0,
      Mode:"NEW",
      list:[],
      isadd:false,
      dataState: {
        take: 0,
        skip: 0,
      },
      columns: [
        {
          title: 'District',
          field: 'district',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'State',
          field: 'stateID',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Is Active',
          field: 'IsActive',
          filter: 'boolean',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        { cell: 'myTemplate1', width: '100px' }        
      ],     
    };
  },
   created: function () {
     this.loadusers();
     this.createDataState({
      take: 10,
      skip: 0,
    });
  },
  
  methods: {
    validate(){
      //eslint-disable-next-line
      const regexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (this.StateID==""){
          return this.error("State ID is required!")
        }
        if (this.DistrictID==""){
          return this.error("District ID is required!")
        }
        if (this.District==""){
          return this.error("District is required!")
        }
       return true
    },
    error(text){
      var _this = this
      if (this.maxalert){
         this.maxalert = false
      }
       this.max = text
         this.maxalert = true
         setTimeout(function(){
                    _this.maxalert = false;
                }, 3000);
                return false;
    },
      isNumber (evt){
    const keysAllowed= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed = evt.key;
    
    if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault()
    }
    },
    edit(n){
      this.Mode="UPDATE"
      this.IsActiveOld = n.IsActive
      this.StateIDOld = n.stateID
      this.DistrictIDOld = n.districtID
      this.DistrictOld = n.district
      this.IsActive = n.IsActive
      this.StateID = n.stateID
      this.DistrictID = n.districtID
      this.District = n.district
      this.isadd=true
    },
    paginationConfig() {
        //  more property : https://docs.telerik.com/kendo-ui/api/javascript/ui/grid/configuration/pageable
        return {
			buttonCount: 4,
            pageSizes: [10, 20, 50, 100],
            numeric: true,
            input: true,
            alwaysVisible: true,
            messages: {
                display: '{0}-{1} of {2} items',
                empty: 'No items to display',
                page: 'Page',
                of: 'of {0}',
                itemsPerPage: 'items per page',
                first: 'Go to the first page',
                last: 'Go to the last page',
                next: 'Go to the next page',
                previous: 'Go to the previous page',
            }
        }
    },
    del(ID){
      const article = {"districtID": ID.districtID};       
      this.axios.post(helper.SERVICEURL+"district/delete", article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  timer:2000,
                  buttons: false, 
                });                 
              this.loadusers()                  
            }  else {
            swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,  
                }); 
            }     
          });
    },
    loadusers(e){
      this.Mode="NEW"
       var article = {};
      if(e != undefined){
        article = e
      }
        this.axios.post(helper.SERVICEURL+"district/get", article)
          .then(response => {
            if(response.data){
              this.list= response.data 
              this.gridData = response.data.slice(0, 10)  
              this.total = response.data.length         
            }      
          });

    },
    save(flag){
        if(!this.validate())
     {
       return 
     }
       const article = {
        "stateID":this.StateID,
        "districtID":this.DistrictID,
        "district":this.District,
        "IsActive":this.IsActive
        };
       var Actionurl= "district/create"
       if (this.Mode=="UPDATE"){
         Actionurl = "district/update"
         //article.stateIDOld = this.StateIDOld 
        //  article.districtIDOld = this.DistrictIDOld 
        //  article.districtOld = this.DistrictOld 
       }           

        this.axios.post(helper.SERVICEURL+Actionurl, article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,
                }); 
                this.StateID = ""
                this.DistrictID = ""
                this.District = ""
                this.IsActive = true
                  if (!flag) { 
                    this.isadd=flag
                    this.loadusers()
                  }
            }  else {
              swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,  
                }); 
            }     
          });

    },
    createDataState(dataState) {
      this.gridData = process(this.list.slice(0), dataState);
      this.total = this.gridData.length
      this.dataState = dataState;
    },
    dataStateChange(e) {
      if (e.event) {
        let isColumnActive = filterGroupByField(e.event.field, e.data.filter);

        // @ts-ignore
        let changedColumn = this.columns.find(
          (column) => column.field === e.event.field
        );

        if (changedColumn) {
          changedColumn.headerClassName = isColumnActive
            ? 'customMenu active'
            : '';
        }
      }
      this.createDataState(e.data);
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
  },
};
</script>

<style>
#fieldlist {
    margin: 0;
    padding: 0;
  }

  #fieldlist li {
    list-style: none;
    padding-bottom: .7em;
    text-align: left;
  }

  #fieldlist label {
    display: block;
    padding-bottom: .3em;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    color: #444;
  }

  #fieldlist li.status {
    text-align: center;
  }

  #fieldlist li .k-widget:not(.k-tooltip),
  #fieldlist li .k-textbox {
    margin: 0 5px 5px 0;
  }

  .confirm {
    padding-top: 1em;
  }

  .valid {
    color: green;
  }

  .invalid {
    color: red;
  }

  #fieldlist li input[type="checkbox"] {
    margin: 0 5px 0 0;
  }

  span.k-widget.k-tooltip-validation {
    display: inline-block;
    width: 160px;
    text-align: left;
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    box-shadow: none;
    color: red;
  }

  .k-tooltip-validation .k-warning {
    display: none;
  }

</style>
