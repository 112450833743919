<template>
  <div>
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">Job Category Master</a></h1>
      <form class="form-inline">
        <button type="button" class="btn btn-primary" style="background-color:#3297fa" v-if="!isadd" @click="isadd=true" >ADD Job Category</button>
        <button type="button" class="btn btn-danger" v-if="isadd" @click="isadd=false" ><i class="far fa-times-circle"></i></button>
      </form>
    </nav>
  </div>
    <div class="grid"  style="margin-top:60px;" v-if="!isadd">
      <!-- <div class="card shadow">           
            <div class="card-body">
              <table class="table table"  v-if="!isadd" >
                <thead class="thead-dark" style="background-color:#3297fa">
                <tr>
                  <th style="background-color:#3297fa; color:white" >JobTitle</th>
                  <th style="background-color:#3297fa; color:white" >Status</th>
                </tr>
                </thead>
                <tr v-for="n in list" :key="n.ID" style="color:black">
                  <td>{{n.jobtitle}}</td>
                  <td>{{n.IsActive}}</td>                  
                  <td style="text-align: center;width:20px"><button class="btn btn-sm btn-info" type="button" @click="edit(n)"><i class="fas fa-edit"></i></button> <button class="btn btn-sm btn-danger" type="button" @click="del(n.jobtitle)"><i class="fas fa-trash"></i></button></td>
                </tr>              
              </table>              
            </div>
    </div> -->
    <Grid
    ref="grid"    
    :data-items="gridData"
    scrollable: true
    :pageable = "true"
    :take="dataState.take"
    :skip="dataState.skip"
    :sort="dataState.sort"
    :total="total"
    :loader="loader"
    :selected-field="selectedField"
    @datastatechange="dataStateChange"
    @filterchange="filterChange"
    @selectionchange="onSelectionChange"
    :columns="columns"
    :filter="dataState.filter"
    :sortable="true"
  >
  <!-- @rowclick="onRowClick" 
  @headerselectionchange="onHeaderSelectionChange"-->
   <template v-slot:myTemplate="{ props }">
      <custom
        :column="props.column"
        :filterable="props.filterable"
        :filter="props.filter"
        :sortable="props.sortable"
        :sort="props.sort"
        :columns="columns"
        @sortchange="(e) => props.onSortchange(e)"
        @filterchange="(e) => props.onFilterchange(e)"
        @closemenu="(e) => props.onClosemenu(e)"
        @contentfocus="(e) => props.onContentfocus(e)"
        @columnssubmit="onColumnsSubmit"
        
      />
  </template>
  <template v-slot:myTemplate1="{props}">
    <actionbtn :data-item="props.dataItem" 
          @edit="edit"
          @remove="del"
          @download="download"/>
  </template>
  </Grid>
    </div>

  <div class="card shadow" v-if="isadd">
    <div class="card-body">
      <div>
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Job Category</label>
              <input type="text" v-model="JobTitle" class="form-control" name="JobTitle" placeholder="Enter Job Category Name">      
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputPassword4">Status</label>
              <div >
                <label class="switch " style="float: left;">
                <input type="checkbox" v-model="IsActive"  class="success">
                <span class="slider"></span>
                </label>
            </div>
            </div> 
          </div> 

            <button type="button" class="btn btn-default" style="float: left;" v-if="isadd && Mode=='NEW'" @click="save(true)" >Save & add</button> 
            <button type="button" class="btn btn-success" style="float: right;" v-if="isadd && Mode=='NEW'" @click="save(false)" >Save & close</button>
            <button type="button" class="btn btn-default" style="float: right;" v-if="isadd && Mode=='UPDATE'" @click="save(false)" >Save & close</button>
        </form>
      </div>
    </div>
      
  </div>    
    
    <transition name="bounce">
      <div id="maxalert" v-if="maxalert"> {{max}} <i class="far fa-times-circle" @click="maxalert=false"></i></div>
    </transition>
  </div>
</template>

<script>
import helper from "../helper.js"
import { Grid, filterGroupByField } from '@progress/kendo-vue-grid';
import ColumnMenu from './ColumnMenu';
import CommandCell from './CommandCell';
import swal from 'sweetalert';
import { process } from '@progress/kendo-data-query';

export default {
  components: {
    'Grid': Grid,
    custom: ColumnMenu,
    'actionbtn': CommandCell,
  },
  data() {
    return {
      JobTitle : "",
      IsActive: true,
      maxalert:false,
      max:0,
      Mode:"NEW",
      list:[],
      isadd:false,
      gridData: [],
      dataState: {
        take: 0,
        skip: 0,
      },
      columns: [
        // { field: 'selected', width: '50px', headerSelectionValue: this.areAllSelected },
        {
          title: 'Job Category',
          field: 'jobtitle',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Is Active',
          field: 'IsActive',
          filter: 'boolean',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        { cell: 'myTemplate1', width: '100px' }        
      ],
      
    };
  },
   created: function () {
     this.loadusers();
     this.createDataState({
      take: 10,
      skip: 0,
    });
  },
  
  methods: {
    validate(){
      //eslint-disable-next-line
      const regexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (this.JobTitle==""){
          return this.error("Job Category is required!")
        }
        
       return true
    },
    error(text){
      var _this = this
      if (this.maxalert){
         this.maxalert = false
      }
       this.max = text
         this.maxalert = true
         setTimeout(function(){
                    _this.maxalert = false;
                }, 3000);
                return false;
    },
      isNumber (evt){
    const keysAllowed= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed = evt.key;
    
    if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault()
    }
    },
    edit(n){
      this.Mode="UPDATE"
      this.IsActiveOld = n.IsActive
      this.JobTitleOld = n.jobtitle
      this.IsActive = n.IsActive
      this.JobTitle = n.jobtitle
      this.isadd=true
    },
    del(ID){
      const article = {"jobtitle": ID.jobtitle};       
      this.axios.post(helper.SERVICEURL+"jobtitle/delete", article)
          .then(response => {
            if(response.data.status){ 
                swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,
                }); 
                   this.loadusers()
                  
            }  else {
                swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false, 
                }); 
            }     
          });
    },
    loadusers(e){
      this.Mode="NEW"
      var article = {};
      if(e != undefined){
        article = e
      }       
        this.axios.post(helper.SERVICEURL+"jobtitle/get", article)
          .then(response => {
            if(response.data){
              this.list= response.data  
              this.gridData = response.data.slice(0, 10)
              this.total = response.data.length                
            }      
          });

    },
    save(flag){
        if(!this.validate())
     {
       return 
     }
       const article = {
        "jobtitle":this.JobTitle,
        "IsActive":this.IsActive
        };
       var Actionurl= "jobtitle/create"
       if (this.Mode=="UPDATE"){
         Actionurl = "jobtitle/update"
         article.jobtitleOld = this.JobTitleOld 
       }           

        this.axios.post(helper.SERVICEURL+Actionurl, article)
          .then(response => {
            if(response.data.status){
                swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,  
                }); 
                this.JobTitle = ""
                this.IsActive = true
                  if (!flag) { 
                    this.isadd=flag
                    this.loadusers()
                  }
            }  else {
                swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,  
                }); 
            }     
          });

    },
    createDataState(dataState) {
      this.gridData = process(this.list.slice(0), dataState);
      this.total = this.gridData.length
      this.dataState = dataState;
    },
    dataStateChange(e) {
      if (e.event) {
        let isColumnActive = filterGroupByField(e.event.field, e.data.filter);

        // @ts-ignore
        let changedColumn = this.columns.find(
          (column) => column.field === e.event.field
        );

        if (changedColumn) {
          changedColumn.headerClassName = isColumnActive
            ? 'customMenu active'
            : '';
        }
      }
      this.createDataState(e.data);
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
  },
};
</script>

<style></style>
