<template>
<nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidenav-main" style="background-color:#1b2a47"> 
    <div class="container-fluid">      
      
      <slot></slot>
      <div class="sidenav">
        <router-link class="navbar-brand" to="/dashboard">
            <img :src="logo" class="navbar-brand-img" alt="..." style="max-height:4rem" />  
        </router-link>
        <div class="sidenav-item">        
          <a href="#/myaccount" v-if="UserID=='Student'"><i class="ni ni-circle-08"></i>My Account</a>
          <a href="#/myapplication" v-if="UserID=='Student'"><i class="ni ni-send"></i>My Applications</a>
          <a href="#/announcements"><i class="ni ni-notification-70" ></i>Announcements</a>

          <a href="#dashboard" v-if="UserID=='admin'"><i class="ni ni-circle-08" ></i>Dashboard</a>
          <a href="#/newapplication" v-if="UserID=='admin'"><i class="ni ni-send"  ></i>Applications</a>
          <!--Master-->
            <!-- <button class="dropdown-btn" @click="clickEvent" v-if="UserID=='admin'"><i class="ni ni-app"></i>Masters
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-container">
              <a href="#/gendermaster"><i class="ni ni-circle-08"></i>Gender</a>
              <a href="#/branchmaster"><i class="ni ni-basket"></i>Branch Master</a>
             
            </div> -->
          <!--Master-->

          <!-- <a href="#/appimport"><i class="ni ni-collection"></i>Application Import</a>           -->
          <a href="#/usermanagement" v-if="UserID=='admin'"><i class="ni ni-single-02"></i>User Management</a>

          <!--Log-->
            <button class="dropdown-btn" v-if="UserID=='admin'" @click="clickEvent"><i class="ni ni-atom"></i>Log
              <i class="fa fa-caret-down"></i>
            </button>
            <div class="dropdown-container">
              <a href="#/activitylog" v-if="UserID=='admin'"><i class="ni ni-box-2"></i>Activity Log</a>
              <!-- <a href="#/userwisedownloadlist" v-if="UserID=='admin'"><i class="ni ni-calendar-grid-58"></i>Userwise Download</a> -->
            </div>
            
          <!--Log-->

          <a href="#/login"><i class="ni ni-button-power"></i>Logout</a>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "sidebar",
  data(){
    return{
      UserID : false,
    }
  },
  components: {
    // NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/logo-new.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  created(){
  },
  mounted(){
    var userDetails = sessionStorage.getItem("sessionDetails");
    if (userDetails===null || userDetails==""){
       window.location.href = '#/login';
    } else {
      var t = JSON.parse(userDetails) //atob(udata)
        this.UserID = t.usergroup
      this.SessionID = t.SessionId
      if(this.SessionID == "" || this.SessionID == undefined || this.SessionID == null){
        window.location.href = '#/login';
      }
    } 
  },
  methods: {
    clickEvent(){
      var dropdown = document.getElementsByClassName("dropdown-btn");
      var i;
      for (i = 0; i < dropdown.length; i++) {
        dropdown[i].addEventListener("click", function() {
          this.classList.toggle("active");
          var dropdownContent = this.nextElementSibling;
          if (dropdownContent.style.display === "block") {
            dropdownContent.style.display = "none";
          } else {
            dropdownContent.style.display = "block";
          }
        });
      }
    },
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style scoped>
.CompanyName{
  color : white;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 26px;
}
/* .navbar-expand-md .navbar-nav .dropdown-menu{
  position: relative!important;
} */
/* .dropdown-menu dropdown-menu-right{
  position : relative
} */
/* Fixed sidenav, full height */
.sidenav {
  overflow: auto;
  height: 100%;
  width: 250px;
  position: fixed;  
  top: 0;
  left: 0;
  background-color: #1b2a47;  
  padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn { 
  font-family: 'Open Sans', sans-serif;
  padding: 0px 8px 6px 16px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}
.sidenav i {
  
  font-size: 16px;
  padding: 10px;
}
.sidenav-item{
  margin-top: 25px;
}

/* On mouse-over */
/* .sidenav a:hover, .dropdown-btn:hover {
  color: black;
} */

/* Main content */
.main {
  margin-left: 200px; /* Same as the width of the sidenav */
  font-size: 20px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
.active {
  background-color: #1b2a47;
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: #1b2a47;
  padding-left: 8px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

</style>
