<template>
  <div>
    <GridColumnMenuFilter
      :expanded="true"
      :column="column"
      :filterable="filterable"
      :filter="filter"
      @filterfocus="handleFocus"
      @closemenu="closeMenu"
      @expandchange="expandChange"
      @filterchange="filterChange"
    />
  </div>
</template>
<script>
import {
  GridColumnMenuFilter,
  
} from '@progress/kendo-vue-grid';

export default {
  props: {
    column: Object,
    sortable: [Boolean, Object],
    sort: {
      type: Array,
    },
    filter: Object,
    filterable: Boolean,
    columns: Array,
  },
  data() {
    return {
      currentColumns: [],
      columnsExpanded: false,
      filterExpanded: false,
    };
  },
  created() {
    this.$data.currentColumns = this.$props.columns;
  },
  components: {
    GridColumnMenuFilter: GridColumnMenuFilter,
  },
  computed: {
    oneVisibleColumn() {
      return this.currentColumns.filter((c) => !c.hidden).length === 1;
    },
  },
  emits: [
    'columnssubmit',
    'closemenu',
    'expandchange',
    'filterchange',
    'sortchange',
  ],
  methods: {
    handleFocus(e) {
      this.$emit('contentfocus', e);
    },
    onToggleColumn(id) {
      this.currentColumns = this.currentColumns.map((column, idx) => {
        return idx === id ? { ...column, hidden: !column.hidden } : column;
      });
    },
    onReset(event) {
      event.preventDefault();
      const allColumns = this.$props.columns.map((col) => {
        return {
          ...col,
          hidden: false,
        };
      });

      this.currentColumns = allColumns;
      this.onSubmit();
    },
    onSubmit(event) {
      if (event) {
        event.preventDefault();
      }
      this.$emit('columnssubmit', this.currentColumns);
      this.$emit('closemenu');
    },
    onMenuItemClick() {
      const value = !this.columnsExpanded;
      this.columnsExpanded = value;
      this.filterExpanded = value ? false : this.filterExpanded;
    },
    onFilterExpandChange(value) {
      this.filterExpanded = value;
      this.columnsExpanded = value ? false : this.columnsExpanded;
    },
    expandChange() {
      this.$emit('expandchange');
    },
    closeMenu() {
      this.$emit('closemenu');
    },
    filterChange(newDescriptor, e) {
      this.$emit('filterchange', newDescriptor, e);
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e);
    },
  },
};
</script>