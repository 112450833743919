<template>
  <div>
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">Activity Log</a></h1>      
    </nav>
  </div>
  <div class="grid" style="margin-top:60px;">
   <Grid
    ref="grid"    
    :data-items="gridData"
    scrollable: true
    :pageable = "true"
    :take="dataState.take"
    :skip="dataState.skip"
    :sort="dataState.sort"
    :total="total"
    :loader="loader"
    :selected-field="selectedField"
    @datastatechange="dataStateChange"
    @filterchange="filterChange"
    @selectionchange="onSelectionChange"
    :columns="columns"
    :filter="dataState.filter"
    :sortable="true"
  >
  <!-- @rowclick="onRowClick" 
  @headerselectionchange="onHeaderSelectionChange"-->
   <template v-slot:myTemplate="{ props }">
      <custom
        :column="props.column"
        :filterable="props.filterable"
        :filter="props.filter"
        :sortable="props.sortable"
        :sort="props.sort"
        :columns="columns"
        @sortchange="(e) => props.onSortchange(e)"
        @filterchange="(e) => props.onFilterchange(e)"
        @closemenu="(e) => props.onClosemenu(e)"
        @contentfocus="(e) => props.onContentfocus(e)"
        @columnssubmit="onColumnsSubmit"
        
      />
  </template>
  <template v-slot:myTemplate1="{props}">
    <actionbtn :data-item="props.dataItem" 
          @edit="edit"
          @remove="del"
          @download="download"/>
  </template>
  </Grid>
  </div>
  </div>
</template>

<script>
import helper from "../helper.js"
import { Grid, filterGroupByField } from '@progress/kendo-vue-grid';
import ColumnMenu from './ColumnMenu';
// import { process } from '@progress/kendo-data-query';

export default {
  components: {
    Grid,
    custom: ColumnMenu,
  },
  data() {
    return {
      result: [],
      filter: null,
      sort: null,
      pageable: true,
      gridData: [],
      list : [],
      skip: 0,
      take: 10,
      total: 0,
      updatedData: [],
      dataState: {
        take: 10,
        skip: 0,
      },
      columns: [
        {
          title: 'User Name',
          field: 'username',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Module',
          field: 'module',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Log',
          field: 'log',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },
        {
          title: 'Date',
          field: 'createdon',
          type: "date",
          format: "{0:dd-MMM-yyyy HH:mm:ss}",
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },          
      ],
      
    };
  },
   created: function () {
     this.getData();
    //  this.createDataState({
    //   take: 9,
    //   skip: 0,
    // }); 
  },
  
  methods: {
    getData: function (e) {
      const that = this;
      that.loader = true;
      var article = {       
      };
      if(e != undefined){
          article = e
      }else{
        article = {"filter":{"filters":[{"logic":"and","filters":[]}],"logic":"and"},"sort":null,"skip":0,"take":10}
      }      
      that.axios.post(helper.SERVICEURL+"activitylog/get", article)
      .then(response => {
            if(response.data){
              that.list= response.data.Data
              that.total = response.data.Total;
              // that.updatedData = [...that.list];
              that.gridData = that.list;
              that.loader = false;               
            }      
          });

      // fetch(
      //   this.baseUrl + '?' + toODataString(this.dataState) + '&$count=true',
      //   this.init
      // )
      //   .then((response) => response.json())
      //   .then((json) => {
      //     const total = json['@odata.count'];
      //     const data = json['value'];
      //     that.total = total;
      //     that.updatedData = [...data];
      //     that.gridData = data;
      //     this.loader = false;
      //   });
    },

    // createDataState(dataState) {
    //   this.result = process(products.slice(0), dataState);
    //   this.dataState = dataState;
    // },

    createDataState(dataState) {
      if(dataState != undefined){
        if(dataState.skip != 0 && dataState.filter == undefined){
          var sk = dataState.skip
          this.getData({"filter":{"filters":[{"logic":"and","filters":[]}],"logic":"and"},"sort":null,"skip":sk,"take":10})
        }
        else if(dataState.skip == 0 && dataState.filter == null){
          this.getData({"filter":{"filters":[{"logic":"and","filters":[]}],"logic":"and"},"sort":null,"skip":0,"take":10})
        }
        else if(dataState != undefined){
          this.getData(dataState)
        }
        else{
          this.getData()
        }
      }      
      this.dataState = dataState;
    },
    dataStateChange(e) {
      if (e.event) {
        let isColumnActive = filterGroupByField(e.event.field, e.data.filter);

        // @ts-ignore
        let changedColumn = this.columns.find(
          (column) => column.field === e.event.field
        );

        if (changedColumn) {
          changedColumn.headerClassName = isColumnActive
            ? 'customMenu active'
            : '';
        }
      }
      this.createDataState(e.data);
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
  },
};
</script>

<style></style>
