<template>
<!-- :theme-color="'primary'" -->
<td class="k-command-cell">
    <kbutton
        style="background-color:#3297fa; color:white"
        class="k-grid-edit-command"
        @click="editHandler"
    >
        <!-- Edit -->
        <i class="fa fa-edit"></i>
    </kbutton>

    <kbutton        
        :theme-color="'primary'"
        class="k-grid-remove-command"
        @click="removeHandler"
    >
        <!-- Remove -->
        <i class="fa fa-trash"></i>
    </kbutton>
</td>
</template>
<script>
import { Button } from '@progress/kendo-vue-buttons';

export default {
    components: {
      'kbutton': Button
    },
    
   props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String
    },
    methods: {
        editHandler: function() {
            this.$emit('edit', this.dataItem);
        },
        removeHandler: function() {
            // if(confirm('Confirm deleting: ' + this.dataItem.ProductName)){
               this.$emit('remove', this.dataItem);
            // }
        }
    }
}
</script>