const users = [
    {
        "UserID": 1,
        "FirstName" : "User1",
        "Email" : "user1@gmail.com",
        "City" : "User1City",
        "Mobile":"9988776655",
        "DOB":"01/01/0001",
    },
    {
        "UserID": 2,
        "FirstName" : "User2",
        "Email" : "user2@gmail.com",
        "City" : "User2City",
        "Mobile":"9988776655",
        "DOB":"01/01/0001",
    },
    {
        "UserID": 3,
        "FirstName" : "User3",
        "Email" : "user3@gmail.com",
        "City" : "User3City",
        "Mobile":"9988776655",
        "DOB":"01/01/0001",
    },
    {
        "UserID": 4,
        "FirstName" : "User4",
        "Email" : "user4@gmail.com",
        "City" : "User4City",
        "Mobile":"9988776655",
        "DOB":"01/01/0001",
    },
    ];

export { users };