export default {
    //SERVICEURL:"https://www.cryptcasi.com:8115/",
     //SERVICEURL:"http://localhost:8114/",
    //SERVICEURL:"https://app.careercraffers.com:8112/",     // local
     SERVICEURL:"https://apply.muantigua.org:8114/", //live
    IsAdmin : false,
    setUserinfo(user){
        user.sstime = new Date(new Date(new Date().setHours(new Date().getHours() + 2)))
        localStorage.setItem("UI", JSON.stringify(user));
    },
    checklogin(){
        var d = localStorage.getItem("UI");
        if (d===null || d==""){
           window.location.href = "/#/login"
           return false
            
        } else {
            var uinfo = JSON.parse(d)
            var time = new Date(uinfo.sstime)
            
            var current = new Date()
            console.log(time.getTime())
            console.log(current.getTime())
            if (time.getTime()<=current.getTime()){
                window.location.href = "/#/login";
                return false
            } else {
                this.UR = uinfo.Roalval
                this.UserInfo =uinfo
            }
        }
    },
    getsitesettings(){
        var d = localStorage.getItem("sitesettings");
        if (d===null || d==""){
            return {}
        } else {
            var p =  JSON.parse(d)
            if (p.length>0) {
                return p[0]
            } else {
                return {}
            } 
        }
    },
    getuserinfo(){
        var d = localStorage.getItem("UI");
        if (d===null || d==""){
            return false
        } else {
            return JSON.parse(d)
        }
    },
    UserInfo:"",
    UR:"",

    // checkAdmin(){
    //     var d = sessionStorage.getItem("sessionDetails");
    //     if (d===null || d==""){
    //         return false
    //     } else {
    //         var us = JSON.parse(d.userID)
    //         this.IsAdmin = us
    //     }
    // },
    csvToArray(str) {
        console.log("Str", str)
        var delimiter = ","
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
          }, {});
          return el;
        });
        return arr.slice(0, arr.length-1);
    },

    GetSessionID(){
        var d = sessionStorage.getItem("sessionDetails");
        if (d===null || d==""){
            return ""
        } else {
            var t = JSON.parse(d) //atob(udata)
            if(t.SessionId != null || t.SessionId != "" || t.SessionId != undefined){
                var SessionID = t.SessionId
                return SessionID
            }else{
                return ""
            }      
            
        }
    },   

    // alert(){
    //     const Toast = Swal.mixin({
    //         toast: true,
    //         position: 'top-end',
    //         showConfirmButton: false,
    //         timer: 3000,
    //         timerProgressBar: true,
    //         didOpen: (toast) => {
    //           toast.addEventListener('mouseenter', Swal.stopTimer)
    //           toast.addEventListener('mouseleave', Swal.resumeTimer)
    //         }
    //       })
    // }
    
}
