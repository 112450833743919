<template>
  <div class="bgbody">


    <div class="wrapperat">
      <div class="image-holder">
        <div class="logo">
          <img src="img/form-wizard.png" alt>
        </div>

      </div>
      <form action>
        <div id="wizard">
          please wait checking your transaction details
          {{ rmsg }}
          </div>
          </form>
    
     
    </div>

    
  </div>
</template>

<script>
import helper from "../helper.js"
//import { DropDownList } from '@progress/kendo-vue-dropdowns';
// import { DatePicker } from '@progress/kendo-vue-dateinputs';
import '@progress/kendo-theme-default/dist/all.css';
//import { MultiSelect } from '@progress/kendo-vue-dropdowns';
import swal from 'sweetalert';
//import Recaptcha from '../components/Recaptcha.vue'
//import Mydatepicker from '../components/Mydatepicker.vue'
import { filterBy } from '@progress/kendo-data-query';
import moment from "moment"
import $ from 'jquery';
//import vueSignature from "vue-signature";
import CryptoJS from 'crypto-js';


export default {
  components: {
    //'dropdownlist': DropDownList,
    // 'datepicker': DatePicker,
    // 'multiselect': MultiSelect,
    //Recaptcha,
    
  },
  data() {
    return {
      rmsg:"",
    };
  },
  mounted() {
  },
  created: function () {
    //rmsg

    const hash = window.location.hash;
    const hashWithoutHashSymbol = hash.slice(1);  
    const parts = hashWithoutHashSymbol.split('?');
    const queryString = parts.length > 1 ? parts[1] : '';
    const params = new URLSearchParams(queryString);
    const pid = params.get('p');
    console.log(pid,params,hashWithoutHashSymbol); // Output: 76616
    this.checkpaymentstatus(pid)
  },
  watch: {
  },

  methods: {
    getQueryParamValue(param){
      const query = window.location.search.substring(1);
      const queryParams = new URLSearchParams(query);
      return queryParams.get(param);
    },
    DownloadFilledApp(){
        window.open(helper.SERVICEURL+"filledpdf?username="+this.mobilenumber, '_blank');
    },  
    docfileclickphoto(){
      this.$refs.fileInputphoto.click();
    },
    docfileclick(dname){
      console.log("comingdocfileclick")
      this.docname = dname
      this.$refs.fileInput.click();
    },
    handlesignuploadUpload(){
      var files = this.$refs.signuploadUpload.files[0];
      var self = this
      const reader = new FileReader();
        reader.onloadend = () => {
          var base64String = reader.result.split(',')[1];
          self.$refs.signature.fromDataURL("data:image/png;base64,"+base64String);
        };
        reader.readAsDataURL(files);
    },
    signuploadUpload(){
      this.$refs.signuploadUpload.click();
    },
    handlesignuploadUpload2(){
      var files = this.$refs.signuploadUpload2.files[0];
      var self = this
      const reader = new FileReader();
        reader.onloadend = () => {
          var base64String = reader.result.split(',')[1];
          self.$refs.signature1.fromDataURL("data:image/png;base64,"+base64String);
        };
        reader.readAsDataURL(files);
    },
    signuploadUpload2(){
      this.$refs.signuploadUpload2.click();
    },
    Approveapplication(){
        
      
      
      var validation = true
      if(validation) {

        var param = {
            username : this.mobilenumber,
            RollNo : this.RollNo,
            UniversityRegnNo:this.UniversityRegnNo,
            Section:this.Section,
            TotalDocuments:this.TotalDocuments,
            Othersdoc:this.Othersdoc,
            SCorSTBankAccNodoc:this.SCorSTBankAccNodoc,
            ConductCertificatedoc:this.ConductCertificatedoc,
            CommunityCertificatedoc:this.CommunityCertificatedoc,
            SCorSTIncomeCertificatedoc:this.SCorSTIncomeCertificatedoc,
            SCorSTAttendanceCertificatedoc:this.SCorSTAttendanceCertificatedoc,
            TransferCertificatedoc:this.TransferCertificatedoc,
            sslcdoc:this.sslcdoc,
          }
       
           
            param.ApplicationStatus ="approved",
            param.applicationID=this.applicationID
        
          if(this.saveapplication(param)){
              this.ApplicationStatus = "approved"
              alert("Application Approved Successfully!")
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
      }

     
        
    },
    checkpaymentstatus(pid){
      let param = {}
      param.SessionId = self.sessionid
      param.pgid = pid
this.axios.get("https://eazypayuat.icicibank.com/EazyPGVerify?ezpaytranid=&amount=&paymentmode=&merchantid=138963&trandate=&pgreferenceno=76616& dstatus=Y")
.then(result=>{
  console.log("result===>",result)
}).catch(e=>{
  console.log("browsererror------->",e)
})

      
      this.axios.post(helper.SERVICEURL + "payment/getstatus", param) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
             self.rmsg = tempdata.msg
            } 
          }
        });
    },
    async encryptData(data, key) {
    const encodedData = new TextEncoder().encode(data);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encodedData
    );
    return { encryptedData, iv };
  },
  ency(pgid){
    var aeskey ="1300011789605020"
    var encryptedData= CryptoJS.AES.encrypt(pgid.toString(), aeskey).toString();
    return encryptedData
  },
    PayNow(){
        var pgid = Math.floor(Math.random() * (99999 - 1)) + 1;
        //var purl = "https://eazypayuat.icicibank.com/EazyPG?merchantid=138963&mandatory fields="+this.ency(pgid+"|45|45")+"&optional fields=&returnurl="+this.ency("http://cryptcasi.com/apdemo/#/payment")+"&Reference No="+this.ency(pgid)+"&submerchantid="+this.ency("45")+"&transaction amount="+this.ency("45")+"&paymode="+this.ency("9")
        var purl = "https://eazypayuat.icicibank.com/EazyPG?merchantid=138963&mandatory fields="+this.ency(pgid+"|45|45")+"&optional fields=&returnurl=WnaKAQxc5o9FXFsv9hZqK2d3ogyVvcKSllI+VRhBdQwxYxHVcFEPcJaG34FeBAbB&Reference No="+this.ency(pgid)+"&submerchantid=OQ/Ws3HpTzxGjEjT0Wi9oA==&transaction amount=W0jnMXj1N3Fcfu2H61w3+Q==&paymode=38EOaD0us69jnS25U5SKbA=="
        console.log("purl----------->",purl)
        window.open(purl, '_blank');
    },
    submitapplication(){
      var validation = true
      if(!this.digitalsign){
      if(this.$refs.signature1.isEmpty()){
        $("#signature1err").html("Parent / Guardian signature can't be empty.")
        validation=false
      } else {
        $("#signature1err").html("")
      }
      if(this.$refs.signature.isEmpty()){
        $("#signatureerr").html("Applicant signature can't be empty.")
        validation=false
      } else {
        $("#signatureerr").html("")
      }
    }
     
      if(this.Place==""){
        this.vmsg("Place","Place Can't be empty!")
        validation=false
      } else {
        this.vmsg("Place","")
      }
      
      if(this.Signatureby==""){
        this.vmsg("Signatureby","Signature by Can't be empty!")
        validation=false
      } else {
        this.vmsg("Signatureby","")
      }

      if(this.Signatureby=="Guardian" && (this.GuardianName=="")){
        this.vmsg("GuardianName","Guardian Name Can't be empty!")
        validation=false
      } else {
        this.vmsg("GuardianName","")
      }
      this.calculateprofileper()

      if(this.profilecompleted!=100){
        alert("Can't Submit your application! Fill all mandatory fields")
      }
      
      

      if(validation) {
        var png = ""
        var png1 =""
        if(!this.digitalsign){
          png = this.$refs.signature.save();
          png1 = this.$refs.signature1.save();
        }       
        //console.log("png-->",png,png1)

        var param = {
            username : this.mobilenumber,
            ApplicantSign : png,
            ParentSign:png1,
            Place:this.Place,
            digitalsign:this.digitalsign,
            IPaddress:this.IPaddress,
            Signatureby:this.Signatureby,
            GuardianName:this.GuardianName,
           
          }
          if(this.profilecompleted!=100){
            //emptryblock
          } else {
            param.SubmitedDate= this.currentdate,
            param.Createdon=this.today,
            param.ApplicationStatus ="submitted",
            param.applicationID=this.applicationID
          }
          if(this.saveapplication(param)){
            if(this.profilecompleted==100){
              this.ApplicationStatus = "submitted"
              alert("Your Application Submitted Successfully!")
            }
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
      }

     
        
    },
    clear(){
      this.$refs.signature.clear();
    },
    clear2(){
      this.$refs.signature1.clear();
    },
    downloadFile(url){
      window.open(helper.SERVICEURL+"download?filename="+url, '_blank');
    },
    deletedocprofile(f){
      var param = f
      param.username = this.mobilenumber
      param.sessionid = this.sessionid
      var self = this
      this.axios.post(helper.SERVICEURL + "deletedoc", param) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
             self.ApplicantPhoto.path=""
             self.ApplicantPhoto.docname=""
             // return this.error("Application Not saved!")
            } 
          }
        });
    },
    deletedoc(f){
      var param = f
      param.username = this.mobilenumber
      param.sessionid = this.sessionid
      var self = this
      this.axios.post(helper.SERVICEURL + "deletedoc", param) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              self.Docs = tempdata.Docs
             // return this.error("Application Not saved!")
            } 
          }
        });
    },
    handleProfilePhoto() {

      this.selectedFile = this.$refs.fileInputphoto.files[0];
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(this.selectedFile.type)) {
        alert('Only JPEG, PNG, and GIF files are allowed.');
        return false;
      }
      const maxSizeMB = 2; // Maximum size allowed in megabytes
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      if (this.selectedFile.size > maxSizeBytes) {
        alert(`File size exceeds 2MB limit.`);
        return false;
      }

const formData = new FormData();
formData.append('uploadedFile', this.selectedFile);
formData.append('username', this.mobilenumber);
formData.append('docname', "Applicant Photo");
var self = this
  this.axios.post(helper.SERVICEURL + "upload", formData,{
headers: {
  'Content-Type': 'application/x-www-form-urlencoded',
  // Add any additional headers if needed
},
}) //header
  .then(response => {
    console.log("res-->",response)
    if (response.data) {
      var tempdata = response.data
      if (tempdata.status) {
        tempdata.Docs.forEach(x => {
                if (x.docname=="Applicant Photo"){
                  self.ApplicantPhoto.docname = x.docname
                  self.ApplicantPhoto.path=x.path
                }
              })
       // return this.error("Application Not saved!")
      } 
    }
  });
},
    handleFileChange() {
      this.selectedFile = this.$refs.fileInput.files[0];

      const formData = new FormData();
      formData.append('uploadedFile', this.selectedFile);
      formData.append('username', this.mobilenumber);
      formData.append('docname', this.docname);
      var self = this
        this.axios.post(helper.SERVICEURL + "upload", formData,{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Add any additional headers if needed
      },
    }) //header
        .then(response => {
          console.log("res-->",response)
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              //self.Docs = tempdata.Docs
              self.Docs.forEach((element, index) => {
                tempdata.Docs.forEach(x => {
                          if (x.docname==element.docname){
                            self.Docs[index].path = x.path
                          }
                        });
                });
             // return this.error("Application Not saved!")
            } 
          }
        });
    },
    addmoreCurricular(){
      var validated = true
      if (this.SportsGames==""){
          this.vmsg("SportsGames","Sports/Games can't be empty.")
          validated=false
        }  else {
          this.vmsg("SportsGames","")
        }

        if (this.National==""){
          this.vmsg("National","National can't be empty.")
          validated=false
        }  else {
          this.vmsg("National","")
        }

        if (this.ECState==""){
          this.vmsg("ECState","State can't be empty.")
          validated=false
        }  else {
          this.vmsg("ECState","")
        }

        if (this.Devision==""){
          this.vmsg("Devision","Devision can't be empty.")
          validated=false
        }  else {
          this.vmsg("Devision","")
        }

        if (this.ECDistrict==""){
          this.vmsg("ECDistrict","District can't be empty.")
          validated=false
        }  else {
          this.vmsg("ECDistrict","")
        }

        if(this.ExtraCurricular.length>=3){
          alert("Can't Enter More than 3 Extra Curricular")
          validated=false
        }

        if (validated) {
          var jobj = {
            SportsGames:this.SportsGames,
            National:this.National,
            ECState:this.ECState,
            Devision:this.Devision,
            ECDistrict:this.ECDistrict,
          }

          this.ExtraCurricular.push(jobj)
          this.resetExtraCurricular()

        }
    },
    removeqa(i){
      this.Qualifying.splice(i, 1);
    },
    removeExtraCurricular(i){
      this.ExtraCurricular.splice(i, 1);
    },
    resetExtraCurricular(){
      this.SportsGames=""
      this.National=""
      this.ECState=""
      this.Devision=""
      this.ECDistrict=""
    },
    addmoreQualifying(){
      var validated = true
      var today = new Date()
      var currentyear = today.getFullYear() 
      if (this.yearpassed==""){
          this.vmsg("yearpassed","Year passed can't be empty.")
          validated=false
        } else  if (this.yearpassed>currentyear){
          this.vmsg("yearpassed","Year passed can't be more than current year.")
          validated=false
        } else {
          this.vmsg("yearpassed","")
        }

        if (this.regno==""){
          this.vmsg("regno","Reg no can't be empty.")
          validated=false
        } else {
          this.vmsg("regno","")
        }

        if (this.markscored.length==0){
          this.vmsg("markscored","Mark scored can't be empty.")
          validated=false
        } else {
          this.vmsg("markscored","")
        }

        if (this.totalmark.length==0){
          this.vmsg("totalmark","Total Mark can't be empty.")
          validated=false
        } else {
          this.vmsg("totalmark","")
        }

        if (this.Institution==""){
          this.vmsg("Institution","Institution can't be empty.")
          validated=false
        } else {
          this.vmsg("Institution","")
        }

        if (this.Medium==""){
          this.vmsg("Medium","Medium can't be empty.")
          validated=false
        } else {
          this.vmsg("Medium","")
        }
        if (this.Qualifying.some(obj => obj["Particular"] === this.Particular)) {
          this.vmsg("Particular","Particular Already added!")
          validated=false
        } else {
          this.vmsg("Particular","")
        }

        if (validated) {
          var jobj = {
            Particular : this.Particular,
            yearpassed :this.yearpassed,
            regno:this.regno,
            markscored:this.markscored,
            waitingforresult:this.waitingforresult,
            totalmark:this.totalmark,
            markpre:this.markpre,
            Institution:this.Institution,
            Medium:this.Medium,
            Attempts:this.Attempts,
          }

          this.Qualifying.push(jobj)
          this.resetqa()

        }

    },
    resetqa(){
      this.Particular="SSLC"
      this.yearpassed=""
      this.regno=""
      this.markscored=""
      this.waitingforresult = false
      this.totalmark=""
      this.markpre=""
      this.Institution=""
      this.Medium=""
      this.Attempts=""
    },
    validate4(){
      var validated = true

      console.log("this.Branch=",this.Branch)
      if (this.Branch=="" || this.Branch==undefined){
          this.vmsg("Branch","Branch of Study can't be empty.")
          validated=false
        } else {
          this.vmsg("Branch","")
        }

        if (this.Transport==""){
          this.vmsg("Transport","Mode of Transport can't be empty.")
          validated=false
        } else {
          this.vmsg("Transport","")
        }

        if (this.Hostel==""){
          this.vmsg("Hostel","Required.")
          validated=false
        } else {
          this.vmsg("Hostel","")
        }

        if (this.BloodGroup==""){
          this.vmsg("BloodGroup","Blood Group Required.")
          validated=false
        } else {
          this.vmsg("BloodGroup","")
        }

        if (this.exserviceman==""){
          this.vmsg("exserviceman","Ex-serviceman Required.")
          validated=false
        } else {
          this.vmsg("exserviceman","")
        }

        if (this.firstgraduate==""){
          this.vmsg("firstgraduate","First graduate Required.")
          validated=false
        } else {
          this.vmsg("firstgraduate","")
        }

        if (this.admittingyear=="" || this.admittingyear==undefined){
          this.vmsg("admittingyear","Admitting year Required.")
          validated=false
        } else {
          this.vmsg("admittingyear","")
        }

        if (this.singleChild==""){
          this.vmsg("singleChild","single Child Required.")
          validated=false
        } else {
          this.vmsg("singleChild","")
        }


        return validated
    },
    validate1(){
        var validated = true
        if (this.Name==""){
          this.vmsg("Name","Name can't be empty.")
          validated=false
        } else if (this.Name.length>24) {
          this.vmsg("Name","Name can't be more than 24 character.")
          validated=false
        } else {
          this.vmsg("Name","")
        }

        if (this.Gender==""){
          this.vmsg("Gender","Gender can't be empty.")
          validated=false
        } else {
          this.vmsg("Gender","")
        }

        if (this.DOB==""){
          this.vmsg("DatepickerDOB","DOB can't be empty.")
          validated=false
        } else {
          this.vmsg("DatepickerDOB","")
        }

        if (this.MotherTongue==""){
          this.vmsg("MotherTongue","Mother Tongue can't be empty.")
          validated=false
        } else {
          this.vmsg("MotherTongue","")
        }
        
  
        if (this.Religion==""){
          $("#Religion").html("Religion can't be empty.")
          validated=false
        } else {
          $("#Religion").html("")
        }
        if (this.Nationality==""){
          $("#Nationality").html("Nationality can't be empty.")
          validated=false
        } else {
          $("#Nationality").html("")
        }
        if (this.State==""){
          $("#State").html("State can't be empty.")
          validated=false
        } else {
          $("#State").html("")
        }
        
        
        if (this.Community==""){
          this.vmsg("Community","Community can't be empty.")
          validated=false
        } else {
          this.vmsg("Community","")
        }

        if (this.Caste==""){
          this.vmsg("Caste","Caste can't be empty.")
          validated=false
        } else {
          this.vmsg("Caste","")
        }

        if (this.AadharNo==""){
          this.vmsg("AadharNo","Aadhar No can't be empty.")
          validated=false
        } else {
          this.vmsg("AadharNo","")
        }

        if (this.CommunicationAddress==""){
          this.vmsg("CommunicationAddress","Communication Address can't be empty.")
          validated=false
        } else {
          this.vmsg("CommunicationAddress","")
        }

        if (this.Post==""){
          this.vmsg("Post","Post can't be empty.")
          validated=false
        } else {
          this.vmsg("Post","")
        }
        if (this.Taluk==""){
          this.vmsg("Taluk","Taluk can't be empty.")
          validated=false
        } else {
          this.vmsg("Taluk","")
        }
        if (this.District==""){
          this.vmsg("District","District can't be empty.")
          validated=false
        } else {
          this.vmsg("District","")
        }
        if (this.Pincode==""){
          this.vmsg("Pincode","Pincode can't be empty.")
          validated=false
        } else {
          this.vmsg("Pincode","")
        }
        if (this.FatherName==""){
          this.vmsg("FatherName","Father Name can't be empty.")
          validated=false
        } else {
          this.vmsg("FatherName","")
        }
        if (this.Occupation==""){
          this.vmsg("Occupation","Occupation can't be empty.")
          validated=false
        } else {
          this.vmsg("Occupation","")
        }
        if (this.MotherName==""){
          this.vmsg("MotherName","Mother Name can't be empty.")
          validated=false
        } else {
          this.vmsg("MotherName","")
        }
        if (this.AnnualIncome==""){
          this.vmsg("AnnualIncome","Annual Income can't be empty.")
          validated=false
        } else {
          this.vmsg("AnnualIncome","")
        }  
        if (this.ApplicantPhoto.path==""){
          this.vmsg("ApplicantPhoto","Profile Photo  can't be empty.")
          validated=false
        } else {
          this.vmsg("ApplicantPhoto","")
        }      
        
        

        return validated
    },
    validate3(){
      var validated = true
      var requiredfields = ["SSLCTamilq1", "SSLCTamilMY","SSLCEnglishq1",  "SSLCEnglishMY","SSLCMathq1",  "SSLCMathMY","SSLCScienceq1",  "SSLCScienceMY","SSLCSocialsq1",  "SSLCSocialsMY", "HSCTamilq1",  "HSCTamilMY","HSCEnglishq1",  "HSCEnglishMY","HSCPhysicsq1",  "HSCPhysicsMY","HSCChemistryq1",  "HSCChemistryMY","HSCMathq1",  "HSCMathMY","HSCBioq1",  "HSCBioMY","HSCtq1",  "HSCtMY","HSCPractical1q1",  "HSCPractical1MY","HSCPractical2q1",  "HSCPractical2MY"]
        
      for (let index = 0; index < requiredfields.length; index++) {
        if (this.$data[requiredfields[index]].length==0){
          this.vmsg(requiredfields[index],"Require")
          validated=false
        } else {
          this.vmsg(requiredfields[index],"")
        }
      }
        return validated
    },
    calculateAge(birthdate) {
  // Parse the birthdate string into a Date object
  const birthDateObj = birthdate;

  // Get the current date
  const currentDate = new Date();
      console.log("birthDateObj-->",birthDateObj)
  // Calculate the difference in years
  const age = currentDate.getFullYear() - birthDateObj.getFullYear();

  // Adjust the age if the birthday hasn't occurred yet this year
  if (
    currentDate.getMonth() < birthDateObj.getMonth() ||
    (currentDate.getMonth() === birthDateObj.getMonth() &&
      currentDate.getDate() < birthDateObj.getDate())
  ) {
    return age - 1;
  }

  return age;
},
calculateprofileper(){
  var filled = 0
  var requiredfields = ["Name","Gender","DOB","Community","Caste","AadharNo","Religion","CommunicationAddress", "Post","Taluk", "District","Pincode", "FatherName", "Occupation", "MotherName", "AnnualIncome", "Place","Signatureby","Transport","admittingyear","Branch", "Hostel","BloodGroup","exserviceman","firstgraduate","singleChild","ApplicantSign","ParentSign","SSLCTamilq1",  "SSLCTamilMY","SSLCEnglishq1",  "SSLCEnglishMY","SSLCMathq1", "SSLCMathMY","SSLCScienceq1",  "SSLCScienceMY","SSLCSocialsq1",  "SSLCSocialsMY", "HSCTamilq1",  "HSCTamilMY","HSCEnglishq1",  "HSCEnglishMY","HSCPhysicsq1", "HSCPhysicsMY","HSCChemistryq1",  "HSCChemistryMY","HSCMathq1",  "HSCMathMY","HSCBioq1",  "HSCBioMY","HSCtq1",  "HSCtMY","HSCPractical1q1",  "HSCPractical1MY","HSCPractical2q1",  "HSCPractical2MY"]
        if (this.digitalsign){
          let index = requiredfields.indexOf("ApplicantSign");
         
           if (index !== -1) {
                    requiredfields.splice(index, 1);
          }
          let index2 = requiredfields.indexOf("ParentSign");
          if (index2 !== -1) {
                    requiredfields.splice(index2, 1);
        }
        }
        for (let index = 0; index < requiredfields.length; index++) {
          if(this.$data[requiredfields[index]].length!=0 && this.$data[requiredfields[index]]!=undefined){
           // console.log(requiredfields[index],this.$data[requiredfields[index]])
            filled=filled+1
          } else {
            console.log("non filled-->",requiredfields[index])
          }
        }

        if (this.Qualifying>0){
          filled=filled+1
        }
       var tol = requiredfields.length
       const percentage = (filled / tol) * 100;
       console.log("filled-->",filled,tol)
       if (percentage>0){
        this.profilecompleted = parseInt(percentage)
       }

        
},
saveapplication(param){
      var self = this
      var status = true
      param.SessionId = self.sessionid
      this.axios.post(helper.SERVICEURL + "applications/create", param) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              self.calculateprofileper()
             // return this.error("Application Not saved!")
            } 
          }
        });
        return status
},
back(){
  if (this.currentstage!=1){
    this.currentstage = this.currentstage -1
  }
},
    next(){
      if (this.currentstage==1){
        if(!this.validate1()){
          return false
        } else {
          var param = {
            username : this.mobilenumber,
            Name: this.Name.toUpperCase(),
            Gender:this.Gender,
            DOB:this.DOB,
            MotherTongue:this.MotherTongue,
            Religion:this.Religion,
            Nationality:this.Nationality,
            State:this.State,
            Community:this.Community,
            Caste:this.Caste,
            AadharNo:this.AadharNo,
            CommunicationAddress:this.CommunicationAddress,
            Post:this.Post,
            Taluk:this.Taluk,
            District:this.District,
            Pincode:this.Pincode,
            FatherName:this.FatherName,
            Occupation:this.Occupation,
            MotherName:this.MotherName,
            AnnualIncome:this.AnnualIncome,
            Age:this.Age,
            WhatsAppNo:this.WhatsAppNo,
            isotherReligion:this.isotherReligion,
          }
          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Name is required!")
          }
        }
      }
      //stage 2
      if (this.currentstage==2){
        if(this.Qualifying.length==0){
          alert("Add your SSLC / HSC Qualifying Examination.")
          return false
        } else {
            param = {
            username : this.mobilenumber,
            Qualifying : this.Qualifying
          }
          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
        }
      }
      //stage 3
      if (this.currentstage==3){
        if(!this.validate3()){
          return false
        } else {
            param = {
            username : this.mobilenumber,
          }

          var requiredfields = ["SSLCTamilq1",  "SSLCTamilMY","SSLCEnglishq1",  "SSLCEnglishMY","SSLCMathq1",  "SSLCMathMY","SSLCScienceq1",  "SSLCScienceMY","SSLCSocialsq1",  "SSLCSocialsMY", "HSCTamilq1",  "HSCTamilMY","HSCEnglishq1",  "HSCEnglishMY","HSCPhysicsq1",  "HSCPhysicsMY","HSCChemistryq1",  "HSCChemistryMY","HSCMathq1",  "HSCMathMY","HSCBioq1",  "HSCBioMY","HSCtq1",  "HSCtMY","HSCPractical1q1", "HSCPractical1MY","HSCPractical2q1", "HSCPractical2MY"]
        
        for (let index = 0; index < requiredfields.length; index++) {
          param[requiredfields[index]] = this.$data[requiredfields[index]]
        }
        param.NameVocational = this.NameVocational

          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
        }
      }
       //stage 4
       if (this.currentstage==4){
        if(!this.validate4()){
          return false
        } else {
            param = {
            username : this.mobilenumber,
            PhysicalIdentification1 : this.PhysicalIdentification1,
            PhysicalIdentification2: this.PhysicalIdentification2,
            Transport: this.Transport,
            admittingyear:this.admittingyear,
            Branch:this.Branch,
            Route: this.Route,
            Stage: this.Stage,
            BoardingPoint: this.BoardingPoint,
            Hostel: this.Hostel,
            BloodGroup: this.BloodGroup,
            exserviceman: this.exserviceman,
            exservicemanNo: this.exservicemanNo,
            exservicemanRank: this.exservicemanRank,
            firstgraduate: this.firstgraduate,
            singleChild: this.singleChild,
            ExtraCurricular: this.ExtraCurricular,
            differentlyabled: this.differentlyabled,
            NSSYRC: this.NSSYRC,
          }

       
          if(this.saveapplication(param)){
            this.currentstage = this.currentstage+1
            return false
          } else {
            this.errorFlag = true
            return this.error("Something Went Wrong!")
          }
        }
      }

    },
    vmsg(id,text){
      if (text==""){
        $('#'+id).next("p.errtext").remove();
        $('#'+id).removeClass('error-border');
      } else {
        $('#'+id).next("p.errtext").remove();
        $('#'+id).after('<p class="errtext">'+text+'</p>')
        $('#'+id).addClass('error-border');
      }
    },
    logincheck(){
      const pattern = /^\d{10}$/;
      //var temptext =        this.mobilenumber+""
      if(!pattern.test(this.mobilenumber)){
        this.vmsg("mobilenumber","Phone number has to be 10 digits long.")
      } else {
        this.vmsg("mobilenumber","")
        const article = { "Mobilenumber": this.mobilenumber };
      var self = this
      if (this.SkipOTP){
              self.sessionid = this.adminsessionid
              self.login=false
              self.OTPpage=false
              self.formpage=true
              self.currentstage=1
              self.loaduser()
      } else {
      this.axios.post(helper.SERVICEURL + "user/sendotp", article) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              self.login=false
              self.OTPpage=true
            } else {
              self.vmsg("mobilenumber",tempdata.Msg)
            }
          }
        });
      }
        
      }
    },
    otpcheck(){

      const pattern = /^\d{4}$/;
      //var temptext =        this.mobilenumber+""
      if(!pattern.test(this.otp)){
        this.vmsg("otp","OTP number has to be 4 digits long.")
      } else {
        this.vmsg("otp","")
        const article = { "otp": this.otp , "username":this.mobilenumber};
      var self = this
      this.axios.post(helper.SERVICEURL + "user/validateotp", article) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.status) {
              self.sessionid = tempdata.sessionid
              console.log("comingotpvalidate---",tempdata)
              self.login=false
              self.OTPpage=false
              self.formpage=true
              self.currentstage=1
              self.loaduser()
            } else {
              self.vmsg("otp",tempdata.Msg)
            }
          }
        });
        
      }
    },
    isemailexsit() {
      const article = { "MailId": this.MailId };
      var self = this
      this.axios.post(helper.SERVICEURL + "applications/isemailexists", article) //header
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            if (tempdata.Isemailexists) {
              self.Isemailexists = true
              this.errorFlag = true
              return this.error("Email id already exists!")
            } else {
              self.Isemailexists = false
            }
          }
        });
    },
    setdob(e) {
      this.DOB = moment(e, 'DD-MM-YYYY')
    },
    filterChange(event) {
      this.WorkLocationList = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.MainWorkLocationList;
      return filterBy(data, filter);
    },
    PreffilterChange(event) {
      this.PreferredLocationList = this.PreffilterData(event.filter);
    },
    PreffilterData(filter) {
      const data = this.MainWorkLocationList;
      return filterBy(data, filter);
    },
    JobfilterChange(event) {
      this.JobCategoryList = this.JobfilterData(event.filter);
    },
    JobfilterData(filter) {
      const data = this.JobCategoryListMain;
      return filterBy(data, filter);
    },
    QualfilterChange(event) {
      this.QualificationList = this.QualfilterData(event.filter);
    },
    QualfilterData(filter) {
      const data = this.QualificationListMain;
      return filterBy(data, filter);
    },
    JobCategoryChange(event) {
      if (event.value == "Other") {
        this.IsJobCategory = true
        this.JobCategory = ""
        this.Designation = ""
      } else {
        this.IsJobCategory = false
        //his.value = event.value;
        var self = this
        self.DesignationList = []
        self.Designation = ""
        const article = { "jobcategory.jobtitle": event.value };
        //  const header = {header : {SessionId: "113"}}
        this.axios.post(helper.SERVICEURL + "designation/get", article) //header
          .then(response => {
            if (response.data) {
              var tempdata = response.data
              tempdata.forEach(x => {
                let temp = x.designation
                self.DesignationList.push(temp)
              })
            }
          });
      }
    },
    onVerify(response) {
      console.log("response--->", response)
      if (response) {
        this.imnotroboot = true;
      }
    },
    validate() {
      this.errorFlag = false
      return new Promise(resolve => {
        // //eslint-disable-next-line
        const regexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (this.Name == "") {
          this.errorFlag = true
          return this.error("Name is required!")
        }
        if (this.MailId == "") {
          this.errorFlag = true
          return this.error("Email is required!")
        } else if (this.Isemailexists) {
          this.errorFlag = true
          return this.error("Email id already exists!!")
        } else if (!this.MailId.match(regexp)) {
          this.errorFlag = true
          return this.error("Email is not valid!")
        }
        if (this.Gender == "") {
          this.errorFlag = true
          return this.error("Gender is required!")
        }
        if (this.PhoneNumber == "") {
          return this.error("Phone number is required!")
        } else if (this.PhoneNumber.length != 10) {
          return this.error("Phone number is not valid!")
        }
        if (this.WorkLocation == "") {
          this.errorFlag = true
          return this.error("WorkLocation is required!")
        }
        if (this.Qualification == "") {
          this.errorFlag = true
          return this.error("Qualification is required!")
        }
        if (this.JobCategory == "") {
          this.errorFlag = true
          return this.error("Job Category is required!")
        }
        if (this.Designation == "") {
          this.errorFlag = true
          return this.error("Designation is required!")
        }
        if (this.Experience == "") {
          this.errorFlag = true
          return this.error("Experience is required!")
        }
        if (this.DOB == "") {
          this.errorFlag = true
          return this.error("Date of Birth is required!")
        }
        if (this.PreferredLocation == "") {
          this.errorFlag = true
          return this.error("Preferred Location is required!")
        }
        if (this.TechnicalSkillsComma == "") {
          this.errorFlag = true
          return this.error("Technical Skills is required!")
        }
        if (this.JobAppliedfor == "") {
          this.errorFlag = true
          return this.error("Job Applied for is required!")
        }

        if (this.CtcRange == "") {
          this.errorFlag = true
          return this.error("CTC Range is required!")
        }

        if (this.Experience != "Fresher") {
          this.EmploymentHistory.forEach(x => {
            if (x.PreOrganizationName == undefined || x.PreOrganizationName == "" ||
              x.StartDate == undefined || x.StartDate == "" ||
              x.EndDate == undefined || x.EndDate == "" ||
              x.PreDesignation == undefined || x.PreDesignation == "") {
              this.errorFlag = true
              this.failFlag = true
              return this.error("All Fields in Employment History is Required !")
            }
            if (this.failFlag) {
              return false
            }
          })
        }
        if (!this.imnotroboot) {
          this.errorFlag = true
          return this.error("Verify that you are not a Robot!")
        }
        setTimeout(() => {
          console.log("this.errorFlag", this.errorFlag)
          if (!this.errorFlag) {
            resolve(true)
          } else {
            resolve(false)
          }
        }, "500")
      });
    },
    error(text) {
      var _this = this
      if (this.maxalert) {
        this.maxalert = false
      }
      this.max = text
      this.maxalert = true
      setTimeout(function () {
        _this.maxalert = false;
      }, 3000);
      return false;
    },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    setData(d){
      var self = this
      this.ApplicationStatus = d.ApplicationStatus || ""
      this.AadharNo = d.AadharNo
      this.Age = d.Age
      this.AnnualIncome = d.AnnualIncome
      this.Caste = d.Caste
      this.CommunicationAddress = d.CommunicationAddress
      this.Community = d.Community
      this.DOB = d.DOB
      this.District = d.District
      this.FatherName = d.FatherName
      this.Gender = d.Gender
      this.MotherName = d.MotherName
      this.MotherTongue = d.MotherTongue
      this.Name = d.Name
      this.Nationality = d.Nationality
      this.Occupation = d.Occupation
      this.Pincode = d.Pincode
      this.Post = d.Post
      this.Religion = d.Religion
      this.State = d.State
      this.Taluk = d.Taluk
      this.WhatsAppNo = d.WhatsAppNo
      this.isotherReligion = d.isotherReligion
      this.Qualifying = d.Qualifying || []
      this.digitalsign = d.digitalsign
      

      this.SSLCTamilq1=d.SSLCTamilq1
      // this.SSLCTamilq2=d.SSLCTamilq2
      // this.SSLCTamilq3=d.SSLCTamilq3
      this.SSLCTamilMY=d.SSLCTamilMY

      this.SSLCEnglishq1=d.SSLCEnglishq1
      // this.SSLCEnglishq2=d.SSLCEnglishq2
      // this.SSLCEnglishq3=d.SSLCEnglishq3
      this.SSLCEnglishMY=d.SSLCEnglishMY

      this.SSLCMathq1=d.SSLCMathq1
      // this.SSLCMathq2=d.SSLCMathq2
      // this.SSLCMathq3=d.SSLCMathq3
      this.SSLCMathMY=d.SSLCMathMY

      this.SSLCScienceq1=d.SSLCScienceq1
      // this.SSLCScienceq2=d.SSLCScienceq2
      // this.SSLCScienceq3=d.SSLCScienceq3
      this.SSLCScienceMY=d.SSLCScienceMY

      this.SSLCSocialsq1=d.SSLCSocialsq1
      // this.SSLCSocialsq2=d.SSLCSocialsq2
      // this.SSLCSocialsq3=d.SSLCSocialsq3
      this.SSLCSocialsMY=d.SSLCSocialsMY


      this.HSCTamilq1=d.HSCTamilq1
      // this.HSCTamilq2=d.HSCTamilq2
      // this.HSCTamilq3=d.HSCTamilq3
      this.HSCTamilMY=d.HSCTamilMY

      this.HSCEnglishq1=d.HSCEnglishq1
      // this.HSCEnglishq2=d.HSCEnglishq2
      // this.HSCEnglishq3=d.HSCEnglishq3
      this.HSCEnglishMY=d.HSCEnglishMY

      this.HSCPhysicsq1=d.HSCPhysicsq1
      // this.HSCPhysicsq2=d.HSCPhysicsq2
      // this.HSCPhysicsq3=d.HSCPhysicsq3
      this.HSCPhysicsMY=d.HSCPhysicsMY

      this.HSCChemistryq1=d.HSCChemistryq1
      // this.HSCChemistryq2=d.HSCChemistryq2
      // this.HSCChemistryq3=d.HSCChemistryq3
      this.HSCChemistryMY=d.HSCChemistryMY

      this.HSCMathq1=d.HSCMathq1
      // this.HSCMathq2=d.HSCMathq2
      // this.HSCMathq3=d.HSCMathq3
      this.HSCMathMY=d.HSCMathMY

      this.HSCBioq1=d.HSCBioq1
      // this.HSCBioq2=d.HSCBioq2
      // this.HSCBioq3=d.HSCBioq3
      this.HSCBioMY=d.HSCBioMY

      this.NameVocational=d.NameVocational

      this.HSCtq1=d.HSCtq1
      // this.HSCtq2=d.HSCtq2
      // this.HSCt3=d.HSCt3
      this.HSCtMY=d.HSCtMY

      this.HSCPractical1q1=d.HSCPractical1q1
      // this.HSCPractical1q2=d.HSCPractical1q2
      // this.HSCPractical13=d.HSCPractical13
      this.HSCPractical1MY=d.HSCPractical1MY

      this.HSCPractical2q1=d.HSCPractical2q1
      // this.HSCPractical2q2=d.HSCPractical2q2
      // this.HSCPractical23=d.HSCPractical23
      this.HSCPractical2MY=d.HSCPractical2MY

      this.ExtraCurricular =d.ExtraCurricular || []
      this.PhysicalIdentification1=d.PhysicalIdentification1
      this.PhysicalIdentification2=d.PhysicalIdentification2
      this.Transport=d.Transport
      this.Route=d.Route
      this.Stage=d.Stage
      this.BoardingPoint=d.BoardingPoint
      this.Hostel=d.Hostel
      this.BloodGroup=d.BloodGroup
      this.exserviceman=d.exserviceman
      this.exservicemanNo=d.exservicemanNo
      this.exservicemanRank=d.exservicemanRank
      this.firstgraduate=d.firstgraduate
      this.singleChild=d.singleChild
      this.differentlyabled=d.differentlyabled
      this.NSSYRC=d.NSSYRC

     // this.Docs =d.Docs || []
     this.Docs.forEach((element, index) => {
      d.Docs.forEach(x => {
                if (x.docname==element.docname){
                  self.Docs[index].path = x.path
                }
              });
      });

      d.Docs.forEach(x => {
                if (x.docname=="Applicant Photo"){
                  this.ApplicantPhoto.docname = x.docname
                  this.ApplicantPhoto.path=x.path
                }
              })

      this.Place = d.Place
      this.Signatureby = d.Signatureby || ""
      this.GuardianName = d.GuardianName || ""
      this.Branch = d.Branch
      this.admittingyear = d.admittingyear
      this.ApplicantSign = d.ApplicantSign || ""
      this.ParentSign = d.ParentSign
      this.applicationID = d.applicationID

      this.RollNo= d.RollNo
      this.UniversityRegnNo= d.UniversityRegnNo
      this.Section= d.Section
      //this.TotalDocuments= d.TotalDocuments || 0
      this.Othersdoc= d.Othersdoc || false
      this.SCorSTBankAccNodoc= d.SCorSTBankAccNodoc || false
      this.ConductCertificatedoc= d.ConductCertificatedoc || false
      this.CommunityCertificatedoc= d.CommunityCertificatedoc || false
      this.SCorSTIncomeCertificatedoc= d.SCorSTIncomeCertificatedoc || false
      this.SCorSTAttendanceCertificatedoc= d.SCorSTAttendanceCertificatedoc || false
      this.TransferCertificatedoc= d.TransferCertificatedoc || false
      this.sslcdoc= d.sslcdoc || false
    },
    loaduser() {
      //this.Mode = "NEW"
      const article = {"username":this.mobilenumber};
      //  const header = {header : {SessionId: "113"}}
      this.axios.post(helper.SERVICEURL + "applications/getbyid", article) //header
        .then(response => {
          if (response.data.length>0) {
            console.log("userdata-->",response.data)
            this.setData(response.data[0])
            this.calculateprofileper()
          }
        });

    },

    DOBhandleChange(event) {
      this.DOB = event.target.value;
    },
    branchList(){
      var self = this;
      const article = {
        "IsActive":true
      };
      this.axios.post(helper.SERVICEURL + "branchtitle/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.branchtitle
              self.Branchmaster.push(temp)
            })
          }
        });
    },
    genderList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "gender/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.gender
              self.GenderList.push(temp)
            })
          }
        });
    },

    qualificationList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "qualification/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.qualification
              self.QualificationListMain.push(temp)
              self.QualificationList.push(temp)
            })
          }
        });
    },

    experienceList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "experience/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.experience
              self.ExperienceList.push(temp)
            })
          }
        });
    },

    workLocationList() {
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "district/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.district
              self.MainWorkLocationList.push(temp)
              self.WorkLocationList.push(temp)
              self.PreferredLocationList.push(temp)
            })
          }
        });
    },

    designationList() {
      console.log("@@@@@designationList")
      var self = this;
      const article = {};
      this.axios.post(helper.SERVICEURL + "jobtitle/get", article)
        .then(response => {
          if (response.data) {
            var tempdata = response.data
            tempdata.forEach(x => {
              let temp = x.jobtitle
              self.JobCategoryList.push(temp)
              self.JobCategoryListMain.push(temp)
            })
          }
        });
    },

    handlefile(e) {
      const selectedfile = e.target.files[0]; // get first file
      this.createBase64Image(selectedfile);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var fileBase64 = e.target.result;
        this.FileName = fileObject.name;
        this.file = fileBase64.split(",")[1]
      };
      reader.readAsDataURL(fileObject);
    },

    async save() {

      const result = await this.validate();
      console.log("result", result)
      if (!result) {
        return
      }


      var temparr = []
      if (this.TechnicalSkills.length > 0) {
        this.TechnicalSkills.forEach(x => {
          var tempstr = ""
          tempstr = x.replace(/^\s+|\s+$/gm, '')
          temparr.push(tempstr)
        })
      }
      const article = {
        "Gender": this.Gender,
        "Name": this.Name,
        "MailId": this.MailId,
        "PhoneNumber": this.PhoneNumber,
        "WorkLocation": this.WorkLocation,
        "PreferredLocation": this.PreferredLocation,
        "Qualification": this.Qualification,
        "Designation": this.Designation,
        "JobCategory": this.JobCategory,
        "Experience": this.Experience,
        "JobAppliedfor": this.JobAppliedfor,
        "Filebase": this.file,
        "filename": this.FileName,
        "DOB": this.DOB,
        "TechnicalSkills": temparr,
        "Candidatecommands": this.Candidatecommands,
        "EmploymentHistory": this.EmploymentHistory,
        "CtcRange": this.CtcRange,
        "IsWorklocationOthers": this.IsWorklocationOthers,
        "IsCtcRange": this.IsCtcRange,
        "IsQualification": this.IsQualification,
        "IsExperience": this.IsExperience,
        "IsJobCategory": this.IsJobCategory,
      };
      var Actionurl = "applications/create"
      this.PageLoading = true
      this.axios.post(helper.SERVICEURL + Actionurl, article)
        .then(response => {
          this.PageLoading = false
          if (response.data.status) {
            swal({
              buttons: false,
              text: response.data.Msg,
              timer: 2000,
            });
            this.CloseFlag = true
          } else {
            swal({
              text: response.data.Msg,
              timer: 2000,
              buttons: false,
            });
          }
        });

    },
    RemoveRange(i) {
      var self = this;
      if (self.EmploymentHistory.length == 0) {
        return;
      }
      if (i == undefined) {
        i = self.EmploymentHistory.length - 1;
      }
      self.EmploymentHistory.splice(i, 1);
    },
    AddRange() {
      var condcheck = true
      // this.EmploymentHistory.forEach((v) => {
      // })
      if (condcheck) {
        if (this.EmploymentHistory.length == 0) {
          var tmp = {

          };
          this.EmploymentHistory.push(tmp);
        } else {
          tmp = {

          };
          this.EmploymentHistory.push(tmp);
        }
      }
    },
    ctcchange(e) {
      if (e.value == "Other") {
        this.CtcRange = ""
        this.IsCtcRange = true
      } else {
        this.IsCtcRange = false
      }
    },
    workLocchange(e) {
      if (e.value == "Other") {
        this.IsWorklocationOthers = true
        this.WorkLocation = ""
      } else {
        this.IsWorklocationOthers = false
      }
    },
    QualChange(e) {
      if (e.value == "Other") {
        this.IsQualification = true
        this.Qualification = ""
      } else {
        this.IsQualification = false
      }
    },
    ExepChange(e) {
      if (e.value == "Other") {
        this.IsExperience = true
        this.Experience = ""
      } else {
        this.IsExperience = false
      }
    }
  },
};
</script>

<style>
#maxalertclient {
  position: fixed;
  padding: 20px;
  z-index: 10000;
  color: #fff;
  background: #bf0909;
  bottom: 200px;
  right: 10px;
  border-radius: 10px;
}



@font-face {
  font-family: poppins-regular;
  src: url(../assets/fonts/poppins/Poppins-Regular.ttf)
}

@font-face {
  font-family: poppins-semibold;
  src: url(../assets/fonts/poppins/Poppins-SemiBold.ttf)
}

@font-face {
  font-family: poppins-medium;
  src: url(../assets/fonts/poppins/Poppins-Medium.ttf)
}

@font-face {
  font-family: muli-bold;
  src: url(../assets/fonts/muli/Muli-Bold.ttf)
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

:focus {
  outline: none
}


ul {
  padding: 0;
  margin: 0;
  list-style: none
}

a {
  text-decoration: none
}

textarea {
  resize: none
}

select {
  cursor: pointer;
  border: none
}

select option[value=""][disabled] {
  display: none
}

img {
  max-width: 100%;
  vertical-align: middle
}

.wrapperat {
  max-width: 1400px;
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center
}

.wrapperat .image-holder {
  width: 41%
}

.wrapperat form {
  width: 49%
}

.wizard>.steps .current-info,
.wizard>.steps .number {
  display: none
}

#wizard {
  min-height: 834px;
  background: #fff;

  padding: 107px 75px 65px
}

.steps {
  height: 30px;
}

.steps ul {
  display: flex;
  position: relative
}

.steps ul li {
  width: 20.16%;
  margin-right: 10px;
  border-bottom: none;
}

.steps ul li a {
  display: inline-block;
  width: 100%;
  height: 7px;
  background: #e6e6e6;
  border-radius: 3.5px
}

.steps ul li.first a,
.steps ul li.checked a {
  background: #011142;
  transition: all .5s ease
}



.steps ul.step-2:before {
  content: "Additional Information"
}

.steps ul.step-3:before {
  content: "Your Order"
}

.steps ul.step-4:before {
  content: "Billing Method"
}

h3 {
  font-family: poppins-semibold
}

.form-row {
  margin-bottom: 14px
}

.form-row label {
  margin-bottom: 8px;
  display: block;
  color: black;
}
.form-group{
  margin-bottom:14px !important;
}

.form-row.form-group {
  display: flex
}

.form-row.form-group .form-holder {
  width: 47%;
  margin-right: 21px
}

.form-row.form-group .form-holder:last-child {
  margin-right: 0
}

.form-holder {
  position: relative
}

.form-holder i {
  position: absolute;
  top: 11px;
  right: 19px;
  font-size: 17px;
  color: #999
}

.form-control2 {
  height: 42px;
  border: 1px solid #d2d3d7;
  background: 0 0;
  width: 100%;
  padding: 0 18px;
  color: #818184;
}

.form-control2:focus {
  border-color: #011142
}

.form-control2::-webkit-input-placeholder {
  color: #999;
  font-size: 13px
}

.form-control2::-moz-placeholder {
  color: #999;
  font-size: 13px
}

.form-control2:-ms-input-placeholder {
  color: #999;
  font-size: 13px
}

.form-control2:-moz-placeholder {
  color: #999;
  font-size: 13px
}

textarea.form-control2 {
  padding-top: 11px;
  padding-bottom: 11px
}

.option {
  color: #999
}

.actions ul {
  display: flex;
  margin-top: 30px;
  justify-content: space-between
}

.actions ul.step-last {
  justify-content: flex-end
}

.actions ul.step-last li:first-child {
  display: none
}

.actions li a {
  padding: 0;
  border: none;
  display: inline-flex;
  height: 51px;
  width: 135px;
  align-items: center;
  background: #011142;
  cursor: pointer;
  position: relative;
  padding-left: 41px;
  color: #FFF;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  font-weight: 400
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000 !important;
}
.actions li a:before {
  content: '\f2ee';
  position: absolute;
  top: 19px;
  right: 41px;
  font-family: Material-Design-Iconic-Font;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.actions li a:hover {
  background: #011142
}

.actions li a:hover:before {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1
}

.actions li[aria-disabled=true] a {
  display: none
}

.actions li:first-child a {
  background: #e6e6e6;
  padding-left: 48px
}

.actions li:first-child a:before {
  content: '\f2ea';
  left: 26px
}

.actions li:first-child a:hover {
  background: #ccc
}

.actions li:last-child a {
  padding-left: 29px;
  width: 167px;
  font-weight: 400
}

.actions li:last-child a:before {
  right: 30px
}

.checkbox {
  position: relative
}

.checkbox label {
  padding-left: 21px;
  cursor: pointer;
  color: #999
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer
}

.checkbox input:checked~.checkmark:after {
  display: block
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 12px;
  width: 13px;
  border-radius: 2px;
  background-color: #ebebeb;
  border: 1px solid #ccc;
  font-family: Material-Design-Iconic-Font;
  color: #000;
  font-size: 10px;
  font-weight: bolder
}

.checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  content: '\f26b'
}

.checkbox-circle {
  margin-top: 41px;
  margin-bottom: 46px
}

.checkbox-circle label {
  cursor: pointer;
  padding-left: 26px;
  color: #999;
  display: block;
  margin-bottom: 15px;
  position: relative
}

.checkbox-circle label.active .tooltip {
  display: block
}

.checkbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer
}

.checkbox-circle input:checked~.checkmark:after {
  display: block
}

.checkbox-circle .checkmark {
  position: absolute;
  top: 11px;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #ebebeb;
  border: 1px solid #cdcdcd
}

.checkbox-circle .checkmark:after {
  content: "";
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #666;
  position: absolute;
  display: none
}

.checkbox-circle .tooltip {
  padding: 9px 22px;
  background: #f2f2f2;
  line-height: 1.8;
  position: relative;
  margin-top: 16px;
  margin-bottom: 28px;
  display: none
}

.checkbox-circle .tooltip:before {
  content: "";
  border-bottom: 10px solid #f2f2f2;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  position: absolute;
  bottom: 100%
}

.product {
  margin-bottom: 33px
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px
}

.item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none
}

.item .left {
  display: flex;
  align-items: center
}

.item .thumb {
  display: inline-flex;
  width: 100px;
  height: 90px;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2
}

.item .purchase {
  display: inline-block;
  margin-left: 21px
}

.item .purchase h6 {
  font-family: poppins-medium;
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 500
}

.item .purchase h6 a {
  color: #333
}

.item .price {
  font-size: 16px
}

.checkout {
  margin-bottom: 44px
}

.checkout span.heading {
  font-family: poppins-medium;
  font-weight: 500;
  margin-right: 5px
}

.checkout .subtotal {
  margin-bottom: 18px
}

.checkout .shipping {
  margin-bottom: 19px
}

.checkout .shipping span.heading {
  margin-right: 4px
}

.checkout .total-price {
  font-family: muli-bold;
  color: #333;
  font-weight: 700
}

.logo {
  position: fixed;
  top: 40%;
}

@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px)
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px)
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px)
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px)
  }

  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px)
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px)
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px)
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

.titleh{
    font-size: 22px;
    font-family: poppins-semibold;
    color: #333;
    top: 70px;
    position: absolute;
}
.profileprogress{
  font-size: 12px;
    font-family: poppins-semibold;
    color: #333;
    top: -70px;
    position: relative;
    left: 68%;
    width: 30%;
}

@media(max-width:1500px) {
  .wrapperat {
    height: auto
  }
}

@media(max-width:1199px) {
  .wrapperat {
    height: 100vh
  }

  #wizard {
    margin-right: 40px;
    min-height: 829px;
    padding-left: 60px;
    padding-right: 60px
  }
}

@media(max-width:991px) {
  .wrapperat {
    justify-content: center
  }

  .wrapperat .image-holder {
    display: none
  }

  .logo {
    position: relative;
    padding: 15px;
  }

  .wrapperat form {
    width: 60%
  }

  #wizard {
    margin-right: 0;
    padding-left: 40px;
    padding-right: 40px
  }
  .titleh{
    font-size: 22px;
    font-family: poppins-semibold;
    color: #333;
    top: 0px;
    position: relative;
    
}
}

@media(max-width:767px) {
  .titleh{
    font-size: 22px;
    font-family: poppins-semibold;
    color: #333;
    top: 0px;
    position: relative;
}
  .wrapperat {
    height: auto;
    display: block
  }

  .wrapperat .image-holder {
    width: 100%;
    display: block
  }

  .logo {
    position: relative;
    padding: 15px;
  }

  .wrapperat form {
    width: 100%
  }

  #wizard {
    min-height: unset;
    padding: 70px 20px 40px
  }

  .form-row.form-group {
    display: block
  }

  .form-row.form-group .form-holder {
    width: 100%;
    margin-right: 0;
  }

  .item .purchase {
    margin-left: 11px
  }
}


textarea {
  height: 70px !important;
}
.errtext{
  color: #e00b0b;
}
.error-border{
  border: 1px solid #e00b0b;
}
.uppertext{
  text-transform: uppercase;
}
.qalificationbox{
  background: rgb(1, 17, 66);
    border-radius: 5px;
    margin: 5px;
    color: #fff;
    padding: 10px;
}
.qclose{
  color:#fff;
}
canvas{
  border: solid 1px #000;
}
.clearbtn{
    border-radius: 0px 0px 5px 5px;
    border: solid 1px #890404;
    background: #890404;
    color: #fff;
    font-size: 14px;
}
.Uploadsingbtn{
  border-radius: 0px 0px 5px 5px;
    border: solid 1px #170262;
    background: #170262;
    color: #fff;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
}
.Uploaddocbtn{
  border-radius: 5px;
    border: solid 1px #170262;
    background: #170262;
    color: #fff;
    font-size: 14px;
    margin-left: 5px;
    padding: 10px;
}
</style>
