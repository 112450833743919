<template>
  <div>
    <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">User Wise Download List</a></h1>      
    </nav>
  </div>
  <div class="form-row" style="margin-left:25px">
    <div class="form-group col-md-3">
      <label for="inputEmail4">Start Date</label><datepicker 
      :style="{padding:'5px'}"
      :value="fromdate"
      :format="'dd-MMM-yyyy'"
      @change="startDateChange"
      /> 
    </div>
    <div class="form-group col-md-3">
      <label for="inputEmail4">End Date</label><datepicker 
      :style="{padding:'5px'}"
      :value="enddate"
      :format="'dd-MMM-yyyy'"
      @change="endDatehandleChange"
      />
    </div>  
  </div>
  <div class="grid" style="margin-top:-10px">      
    <Grid
    ref="grid"    
    :data-items="gridData"
    scrollable: true
    :pageable = "true"
    :take="dataState.take"
    :skip="dataState.skip"
    :sort="dataState.sort"
    :total="total"
    :loader="loader"
    :selected-field="selectedField"
    @datastatechange="dataStateChange"
    @filterchange="filterChange"
    @selectionchange="onSelectionChange"
    :columns="columns"
    :filter="dataState.filter"
    :sortable="true"
  >
   <template v-slot:myTemplate="{ props }">
      <custom
        :column="props.column"
        :filterable="props.filterable"
        :filter="props.filter"
        :sortable="props.sortable"
        :sort="props.sort"
        :columns="columns"
        @sortchange="(e) => props.onSortchange(e)"
        @filterchange="(e) => props.onFilterchange(e)"
        @closemenu="(e) => props.onClosemenu(e)"
        @contentfocus="(e) => props.onContentfocus(e)"
        @columnssubmit="onColumnsSubmit"
        
      />
  </template> 
  </Grid>
  </div>
  </div>
</template>

<script>
import helper from "../helper.js"
import { Grid, filterGroupByField } from '@progress/kendo-vue-grid';
import ColumnMenu from './ColumnMenu';
import { process } from '@progress/kendo-data-query';
import { DatePicker } from '@progress/kendo-vue-dateinputs';


export default {
  components: {
    'Grid': Grid,
    custom: ColumnMenu, 
    'datepicker': DatePicker,
  },
  data() {
    return {
      gridData : [],      
      list:[],
      fromdate : new Date(),
      enddate: new Date(),
      columns: [
        {
          title: 'User Name',
          field: '_id',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },        
        {
          title: 'Download Count',
          field: 'Total',
          filter: 'text',
          columnMenu: 'myTemplate',
          headerClassName: 'customMenu',
        },        
      ],
    };
  },
   created: function () {
     this.createDataState({
      take: 10,
      skip: 0,
    });
  },
  mounted(){
    var d = new Date();
    var currMonth = d.getMonth();
    var currYear = d.getFullYear();
    var startDate = new Date(currYear, currMonth, 1);
    this.fromdate = startDate
    this.loadusers()
  },  
  methods: {    
    loadusers(){
       var article = {
        fromdate : this.fromdate,
        enddate : this.enddate
       };       
        this.axios.post(helper.SERVICEURL+"activitylog/getdownloadcount", article)
          .then(response => {
            if(response.data){
              this.list= response.data 
              this.gridData = response.data.slice(0, 10)
              this.total = this.gridData.length               
            }      
          });

    },
    startDateChange(event){
      this.fromdate = event.target.value;
      this.loadusers()
    },
    endDatehandleChange(event){
      this.enddate = event.target.value;
      this.loadusers()
    },
    createDataState(dataState) {
      this.gridData = process(this.list.slice(0), dataState);
      this.total = this.gridData.length
      this.dataState = dataState;
    },
    dataStateChange(e) {
      if (e.event) {
        let isColumnActive = filterGroupByField(e.event.field, e.data.filter);

        // @ts-ignore
        let changedColumn = this.columns.find(
          (column) => column.field === e.event.field
        );

        if (changedColumn) {
          changedColumn.headerClassName = isColumnActive
            ? 'customMenu active'
            : '';
        }
      }
      this.createDataState(e.data);
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
  },
};
</script>

<style>
label.mod-pass-hide{
  position: absolute;
}
</style>
